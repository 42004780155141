import React from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { daDK as daDKMui } from '@mui/x-date-pickers/locales/daDK'
import 'dayjs/locale/da'
import { useTranslation } from 'react-i18next'
import { LocalizationProvider } from '@mui/x-date-pickers'

export const MuiLocalizationProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { i18n } = useTranslation()
  const locale =
    i18n.language === 'da'
      ? {
          localeText:
            daDKMui.components.MuiLocalizationProvider.defaultProps.localeText,
          adapterLocale: 'da',
          dateFormats: {
            keyboardDate: 'DD/MM/YYYY',
          },
        }
      : {
          dateFormats: {
            keyboardDate: 'MM/DD/YYYY',
          },
        }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} {...locale}>
      {children}
    </LocalizationProvider>
  )
}
