import { useResource, useResourcesBySource } from '@/api'
import { useChildren } from '@/features/calendar/domain/mapping/useChildren'
import { calendarModelMapper } from '..'

export const useServiceVisitModel = (visitId: string | undefined) => {
  const { data: serviceVisit } = useResource('ServiceVisit', visitId)
  const { data: serviceAgreement } = useResource(
    'ServiceAgreement',
    serviceVisit?.data.serviceagreement
  )
  const { data: customer } = useResource(
    'Customer',
    serviceVisit?.data.customer ?? serviceAgreement?.data.customer ?? ''
  )

  const { data: notes } = useResourcesBySource(
    'Note',
    'Customer',
    customer?.data.id ?? '',
    serviceVisit?.data.date ?? '',
    '-'
  )
  const { children } = useChildren(serviceVisit)

  const serviceVisitModel =
    serviceVisit !== undefined
      ? calendarModelMapper.mapServiceVisit(
          serviceVisit,
          notes,
          children,
          customer
        )
      : undefined

  return {
    serviceVisitModel,
    customer,
    notes,
    serviceAgreement,
  }
}
