import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()({
  container: {
    display: 'flex',
    position: 'relative',
    backgroundColor: 'azure',
    overflow: 'hidden',
  },
  gutter: { textAlign: 'left', margin: '0px' },
  gutterLabel: {
    fontFamily: 'sans-serif',
    fontSize: 'small',
    position: 'relative',
    marginRight: '1ch',
    margin: '0px',
    top: '-0.5em',
  },
  gutterHourLabel: {
    fontWeight: 'bold',
    fontFamily: 'sans-serif',
    fontSize: 'small',
    position: 'relative',
    marginRight: '1ch',
    margin: '0px',
    top: '-0.5em',
  },
  gutterLines: {
    width: '100%',
    position: 'absolute',
    zIndex: 2,
    margin: '0px',
  },
  gutterLine: {
    position: 'absolute',
    width: '100%',
    borderTop: '2px solid gray',
    marginLeft: '5ch',
    marginRight: '5ch',
  },
  listContent: {
    width: '100%',
    zIndex: 3,
    display: 'flex',
  },
  listColumn: {
    flex: '1',
  },
})
