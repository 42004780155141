import type { RootState } from '@/app'
import { createCalendarSlice } from '@/features/calendar'
import {
  CalendarState,
  defaultInitialState,
} from '@/features/calendar/calendarViewSlice'

const initialState = {
  ...defaultInitialState,
  mode: 'selection',
  date: '2022-01-01',
  visitEditorOpen: false,
  selectedVisit: '',
  selectedGroup: 'd9dfcc2e-ff4d-4398-a403-410a896a66c4',
  selectedRoutes: [],
  legacyGrouping: false,
  scale: 1,
  gutterInterval: 30,
} satisfies CalendarState

export const planningSlice = createCalendarSlice({
  name: 'planning',
  initialState,
})

export type PlanningState = ReturnType<(typeof planningSlice)['reducer']>
const selectPlanning = (state: RootState) => state.planning

export const actions = { ...planningSlice.actions }
export const selectors = {
  selectPlanning,
  selectCalendar: selectPlanning,
  ...planningSlice.selectors,
}

export default planningSlice.reducer
