import { useResource } from '@/api'
import { CancellationsModel } from '@/features/cancellations'

export const useCancellationsModel = () => {
  const { data: cancellations, isLoading: isLoadingCancellations } =
    useResource('PropertySet', 'cancellations')

  const cancellationsModel =
    cancellations !== undefined
      ? new CancellationsModel(cancellations)
      : undefined

  return {
    cancellations: cancellationsModel?.getCancellations(),
    isLoading: isLoadingCancellations,
  }
}
