import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  component: {
    height: '100%',
  },
  controlsContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(1),
  },
  dialogActions: {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  dialogTitle: {
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  dialogContent: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: 0,
  },
  paper: {
    margin: 0,
    maxHeight: `calc(100% - ${theme.spacing(3)})`,
    width: `calc(100% - ${theme.spacing(3)})`,
  },
}))
