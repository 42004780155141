import { useEffect, useState } from 'react'
import { Box, Button, IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { Trans, useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { dateFormat } from '@/common-util/dates'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { useInstallation } from '@/features/app-information'
import { useStyles } from './DateChooser.style'

interface Props {
  date: string
  hideArrows?: boolean
  onGotoDate: (date: string) => void
  onNextDate?: () => void
  onPrevDate?: () => void
  onGotoToday?: () => void
  useLogoAsToday?: boolean
}
export const DateChooser = ({
  date,
  hideArrows,
  onGotoDate,
  onNextDate,
  onPrevDate,
  onGotoToday,
  useLogoAsToday,
}: Props) => {
  const { t } = useTranslation()
  const { isMobile } = useInstallation()
  const { classes } = useStyles()

  const [customDate, setCustomDate] = useState(date)

  useEffect(() => {
    setCustomDate(date)
  }, [date, setCustomDate])

  return (
    <Box
      pt={1}
      pb={1}
      display="flex"
      justifyContent="space-between"
      sx={{ background: '#1c78d3' }}
    >
      <Box display="flex" alignItems="center">
        <Button
          disabled={!useLogoAsToday}
          className={classes.logoButton}
          aria-label={t('planning.today')}
          onClick={() => onGotoToday?.()}
        >
          <img
            src="plana.svg"
            width="40px"
            height="40px"
            style={{ borderRadius: '4px' }}
          />
        </Button>
        {isMobile ? (
          <Box className={classes.mobileContainer}>
            <MobileDatePicker
              localeText={{
                openDatePickerDialogue: () => t('common.label.date'),
              }}
              value={dayjs(customDate, dateFormat)}
              onChange={e => {
                if (e) onGotoDate(e.format(dateFormat))
              }}
              className={classes.datePicker}
            />
          </Box>
        ) : (
          <Box>
            <DesktopDatePicker
              localeText={{
                openDatePickerDialogue: () => t('common.label.date'),
              }}
              value={dayjs(customDate, dateFormat)}
              onChange={e => {
                if (e) onGotoDate(e.format(dateFormat))
              }}
              className={classes.datePicker}
            />
          </Box>
        )}
        {/* <TextField
          id="date"
          size="small"
          title="Date"
          type="date"
          label={t('common.label.date')}
          variant="outlined"
          value={customDate}
          InputLabelProps={{ shrink: true }}
          onChange={e => {
            if (dayjs(e.target.value, dateFormat).isValid())
              onGotoDate(e.target.value)
          }}
        /> */}
        {!hideArrows && onPrevDate && (
          <IconButton onClick={() => onPrevDate()} sx={{ marginLeft: '8px' }}>
            <ArrowBackIcon sx={{ color: 'white' }} />
          </IconButton>
        )}
        {!hideArrows && onNextDate && (
          <IconButton onClick={() => onNextDate()}>
            <ArrowForwardIcon sx={{ color: 'white' }} />
          </IconButton>
        )}
        {!useLogoAsToday && onGotoToday && (
          <Button
            size="small"
            onClick={() => onGotoToday()}
            name="go"
            title="go"
            sx={{ color: 'white' }}
          >
            <Trans i18nKey="planning.today" t={t}>
              Today
            </Trans>
          </Button>
        )}
      </Box>
    </Box>
  )
}
