import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()({
  container: {
    padding: '5px',
    height: 'calc(90vh)',
    overflow: 'clip',
  },
  logo: {
    padding: '5px',
  },
})
