import { selectors as coreSelectors } from '@/features/core'
import { SxProps, Tooltip, Typography, Theme } from '@mui/material'
import { useAppSelector } from '@/app'

interface Props {
  sx?: SxProps<Theme>
}

export const SystemIdentifier = ({ sx = [] }: Props) => {
  const backend = useAppSelector(coreSelectors.selectBackend)
  return (
    <Tooltip title={backend.baseUrl}>
      <Typography variant="subtitle2" sx={[...(Array.isArray(sx) ? sx : [sx])]}>
        {backend.systemId}
      </Typography>
    </Tooltip>
  )
}
