import type { RootState } from '@/app'
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ResourceGroupsState {
  selected: string[]
}

export const initialState: ResourceGroupsState = {
  selected: [],
}

export const resourceGroupsSlice = createSlice({
  name: 'resourceGroupsSelector',
  initialState,
  reducers: {
    setResourceGroups: (state, action: PayloadAction<string[]>) => {
      state.selected = action.payload
    },
  },
})

export const actions = resourceGroupsSlice.actions

const selectResourceGroups = (state: RootState) => state.resourceGroups

const selectSelectedResourceGroups = createSelector(
  selectResourceGroups,
  state => state.selected
)

export const selectors = {
  selectSelectedResourceGroups,
}

export default resourceGroupsSlice.reducer
