import { RootState } from '@/app'
import {
  CalendarState,
  createCalendarSlice,
} from '@/features/calendar/calendarViewSlice'
import { createSelector } from '@reduxjs/toolkit'
import { PropsWithChildren, createContext, useContext, useMemo } from 'react'

type CalendarContextType = {
  selectCalendar: (s: RootState) => CalendarState
  actions: ReturnType<typeof createCalendarSlice>['actions']
  selectors: {
    selectSelectedVisit: (s: RootState) => string
    selectIsSelected: (s: RootState, id: string) => boolean
    selectSelectedGroup: (s: RootState) => string
    selectDate: (s: RootState) => string
    selectScale: (s: RootState) => number
    selectSelectedRoutes: (s: RootState) => string[]
    selectGutterInterval: (s: RootState) => number
    selectLegacyGrouping: (s: RootState) => boolean
    selectIsVisitEditorOpen: (s: RootState) => boolean
    selectMoveVisit: (s: RootState) => CalendarState['moveVisit']
    selectMoveVisitForRoute: (
      s: RootState,
      routeId: string
    ) => CalendarState['moveVisit'] | undefined
  }
}

const CalendarContext = createContext<CalendarContextType | undefined>(
  undefined
)

export type CalendarProps = {
  calendarSelector: (s: RootState) => CalendarState
  calendarActions: ReturnType<typeof createCalendarSlice>['actions']
}

export const CalendarProvider = ({
  calendarSelector,
  calendarActions,
  children,
}: PropsWithChildren<CalendarProps>) => {
  const selectSelectedVisit = createSelector(
    calendarSelector,
    p => p.selectedVisit
  )
  const selectIsSelected = useMemo(
    () =>
      createSelector(
        selectSelectedVisit,
        (_state: RootState, id: string) => id,
        (selectedVisit, id) => selectedVisit === id
      ),
    [selectSelectedVisit]
  )

  const selectSelectedGroup = useMemo(
    () => createSelector(calendarSelector, p => p.selectedGroup),
    [calendarSelector]
  )

  const selectDate = useMemo(
    () => createSelector(calendarSelector, p => p.date),
    [calendarSelector]
  )

  const selectScale = useMemo(
    () => createSelector(calendarSelector, p => p.scale),
    [calendarSelector]
  )
  const selectSelectedRoutes = useMemo(
    () => createSelector(calendarSelector, p => p.selectedRoutes),
    [calendarSelector]
  )
  const selectGutterInterval = useMemo(
    () => createSelector(calendarSelector, p => p.gutterInterval),
    [calendarSelector]
  )
  const selectLegacyGrouping = useMemo(
    () => createSelector(calendarSelector, p => p.legacyGrouping),
    [calendarSelector]
  )

  const selectIsVisitEditorOpen = useMemo(
    () => createSelector(calendarSelector, p => p.visitEditorOpen),
    [calendarSelector]
  )

  const selectMoveVisit = useMemo(
    () => createSelector(calendarSelector, p => p.moveVisit),
    [calendarSelector]
  )

  const selectMoveVisitForRoute = useMemo(
    () =>
      createSelector(
        selectMoveVisit,
        (_state: RootState, routeId: string) => routeId,
        (moveVisit, routeId) =>
          moveVisit.targetRoute === routeId || moveVisit.sourceRoute === routeId
            ? { ...moveVisit }
            : undefined
      ),
    [selectMoveVisit]
  )

  const ctx = {
    selectCalendar: calendarSelector,
    actions: calendarActions,
    selectors: {
      selectSelectedVisit,
      selectIsSelected,
      selectSelectedGroup,
      selectDate,
      selectScale,
      selectSelectedRoutes,
      selectGutterInterval,
      selectLegacyGrouping,
      selectIsVisitEditorOpen,
      selectMoveVisit,
      selectMoveVisitForRoute,
    },
  } satisfies CalendarContextType
  return (
    <CalendarContext.Provider value={ctx}>{children}</CalendarContext.Provider>
  )
}

const useCalendar = () => {
  const ctx = useContext(CalendarContext)
  if (ctx === undefined) {
    throw new Error('useCalendar must be called inside a Calendar provider')
  }
  return ctx
}

export const useCalendarActions = () => useCalendar().actions
export const useCalendarSelectors = () => {
  const { selectors } = useCalendar()

  return {
    ...selectors,
  }
}
