import { propColor } from '@/common-util'
import { Route } from '@alexandrainst/plana-react-api'

class RouteModel {
  private route: Route
  constructor(route: Route) {
    this.route = route
  }

  getRouteColor = () => propColor(this.route)
}

export const routeModel = (route: Route) => new RouteModel(route)
