import { ServiceVisit } from '@alexandrainst/plana-react-api'
import { useState } from 'react'
import { useAppDispatch, useAppSelector } from '@/app'
import {
  useCustomerModel,
  useRouteModel,
  useServiceVisitModel,
} from '@/features/calendar/domain/mapping'
import {
  useCalendarActions,
  useCalendarSelectors,
} from '@/features/calendar/Calendar'
import { useVisitViolationsModel } from '@/features/violations'
import { useCancellationsModel } from '@/features/cancellations'

export const useServiceVisitData = (visit?: ServiceVisit, user?: string) => {
  const visitId = visit?.data.id ?? ''
  const { serviceVisitModel, customer, notes, serviceAgreement } =
    useServiceVisitModel(visitId)

  const state = serviceVisitModel?.getState()
  const isInactive = serviceVisitModel?.isInactive() ?? false
  const stateColor = serviceVisitModel?.getStateColor() ?? ''
  const unseenNotes = serviceVisitModel?.getUnseenNotes(user) ?? []

  const description = serviceVisitModel?.getDescription()
  const title = serviceVisitModel?.getTitle() ?? ''
  const isInternal = serviceVisitModel?.isInternal()

  const { routeModel } = useRouteModel(serviceVisitModel?.getRouteId())
  const routeColor = routeModel?.getRouteColor()

  const { customerModel } = useCustomerModel(customer?.data.id ?? '')

  const phone = customerModel?.getPhone()
  const relatives = customerModel?.getRelatives()
  const relativesPhoneNumbers = customerModel?.getRelativesPhoneNumbers() ?? []

  const customerDescription = customerModel?.getCustomerDescription()
  const keyInformation = customerModel?.getKeyInformation()

  // Violations
  const calculatedVisitId = serviceVisitModel?.getServiceVisitId()
  const { tooltipText } = useVisitViolationsModel(
    visit,
    description ?? '',
    calculatedVisitId
  )

  // Cancellation
  const { cancellations } = useCancellationsModel()

  const cancellationId = serviceVisitModel?.getCancellationId()
  const cancellation = cancellations?.find(c => c.id === cancellationId)

  // Actions
  const dispatch = useAppDispatch()
  const { selectIsSelected } = useCalendarSelectors()
  const isSelected = useAppSelector(s => selectIsSelected(s, visitId))
  const { selectVisit: selectVisitAction } = useCalendarActions()
  const selectVisit = () => dispatch(selectVisitAction(visitId))

  // Component state
  const [showDebug, setShowDebug] = useState(false)

  return {
    description,
    customer,
    customerDescription,
    isInactive,
    isInternal,
    keyInformation,
    notes,
    phone,
    relatives,
    relativesPhoneNumbers,
    routeColor,
    serviceAgreement,
    setShowDebug,
    showDebug,
    state,
    stateColor,
    title,
    unseenNotes,
    violationsTooltipText: tooltipText,
    visit: serviceVisitModel?.getVisit(),
    isSelected,
    selectVisit,
    cancellation,
  }
}
