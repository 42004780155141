import { useAppDispatch, useAppSelector } from '@/app'
import { useCallback } from 'react'
import { actions, selectors as coreSelectors } from './coreSlice'

export const useDebug = () => {
  const debug = useAppSelector(coreSelectors.selectDebug)
  const dispatch = useAppDispatch()
  const toggleDebug = useCallback(
    () => dispatch(actions.setDebug(!debug)),
    [debug, dispatch]
  )
  return { debug, toggleDebug }
}
