import { PropertySchemas, parsedProp } from '@/api/parsing'
import { useChildren } from '@/features/calendar/domain/mapping/useChildren'
import { translateViolation } from '@/features/i18n/GuiConstants'
import {
  PropertySet,
  ServiceVisit,
  Violation,
} from '@alexandrainst/plana-react-api'
import { useTranslation } from 'react-i18next'

export const useResourceIds = (
  visit: ServiceVisit | undefined,
  calculatedVisitId?: string
) => {
  const { childrenIds } = useChildren(visit)
  return childrenIds.length > 0
    ? childrenIds
    : calculatedVisitId !== undefined
      ? [calculatedVisitId]
      : []
}

export const getFilteredRotaViolations = (
  rotaViolations: Violation[],
  rotaViolationsInCalendarContext: Violation[]
) => {
  rotaViolationsInCalendarContext.forEach(s => {
    if (s.data.violationstate === 'Cancelled') {
      // TODO warnings: How to check "getSubType().getCancelles()" from java?
      //v => v.data.subtype !== s.data.subtype.cancelles
      rotaViolations = rotaViolations.filter(v => v)
    } else {
      rotaViolations.push(s)
    }
  })
  return rotaViolations
}

export const useViolationsToolTipTextForVisit = (
  serverConfiguration: PropertySet | undefined,
  violations: Violation[],
  visitDescription: string
) => {
  const { t } = useTranslation()
  const deviation = parsedProp(
    serverConfiguration,
    PropertySchemas.propertySetServerConfiguration.allowedTimeDeviation
  )
  return violations.map(v => {
    const tooltipDescription =
      visitDescription.trim() !== ''
        ? `${visitDescription.substring(0, 50)}: `
        : ''

    const tooltipViolation = translateViolation(t, v)

    const isOutsideAgreementHours =
      v.data.subtype === 'Visit_OutsideServiceAgreementHours' ||
      v.data.subtype === 'RotaVisit_OutsideServiceAgreementHours'
    const tooltipDeviation =
      isOutsideAgreementHours && deviation !== undefined && deviation > 0
        ? ` (± ${deviation} min)`
        : ''

    return tooltipDescription + tooltipViolation + tooltipDeviation
  })
}
