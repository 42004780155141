import { useLayoutEffect, useRef, useState } from 'react'

const minutesToScrollPosition = (minutes: number, scale: number) =>
  Math.max(0, minutes * scale - 60 * scale)

export const useAutoscroll = (
  date: string,
  items: { position: number }[],
  scale: number
) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const [scrollPositions, setScrollPositions] = useState<string[]>([])

  useLayoutEffect(() => {
    const storedScrollPosition = scrollPositions.find(it => it === date)
    if (items.length > 0 && storedScrollPosition === undefined) {
      const newScrollInMinutes = Math.min(...items.map(it => it.position))
      setScrollPositions([...scrollPositions, date])
      scrollContainerRef.current?.scroll(
        0,
        minutesToScrollPosition(newScrollInMinutes, scale)
      )
    }
  }, [items, scale, date, scrollPositions])

  const [prevScale, setPrevScale] = useState(scale)

  useLayoutEffect(() => {
    if (prevScale !== scale) {
      const currentScrollPosition = scrollContainerRef.current?.scrollTop ?? 0
      const prevScrollInMinutes =
        (currentScrollPosition + 60 * prevScale) / prevScale
      scrollContainerRef.current?.scroll(
        0,
        minutesToScrollPosition(prevScrollInMinutes, scale)
      )
      setPrevScale(scale)
    }
  }, [prevScale, scale])

  return { ref: scrollContainerRef }
}
