import { CalendarState } from '@/features/calendar'
import { PlanningState } from './planningSlice'

export const migrations = {
  14: (state: CalendarState): PlanningState => {
    return {
      ...state,
      mode: 'selection',
    }
  },
} as const
