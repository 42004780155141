import { RouteHeader } from './RouteHeader'
import { useStyles } from './RouteHeaderContainer.style'

export interface RouteHeaderContainerProps {
  routes: { id: string; date: string }[]
}

export const RouteHeaderContainer = ({ routes }: RouteHeaderContainerProps) => {
  const { classes } = useStyles()

  return (
    <div className={classes.headerContainer}>
      <div style={{ minWidth: '5ch' }} />
      {routes.map((r, idx) => (
        <div key={idx} className={classes.headerElement}>
          <RouteHeader id={r.id} date={r.date} />
        </div>
      ))}
    </div>
  )
}
