import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()({
  container: {
    padding: '5px',
    overflow: 'clip',
    height: 'calc(100dvh - 116px)',
  },
  routeContainer: {
    position: 'relative',
    overflow: 'clip',
    overflowY: 'scroll',
    overflowX: 'clip',
    height: '100%',
    width: '100%',
  },
  controlsContainer: {
    display: 'flex',
  },

  routecontainer: {
    display: 'flex',
    position: 'relative',
    height: '100%',
  },
  routegutter: {
    width: '6ch',
  },
  routegutterElement: {
    position: 'absolute',
    backgroundColor: 'gray',
    width: '6ch',
    height: '10px',
  },
  routevisitsContainer: {
    flex: '1 1 auto',
  },
})
