import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  list: {
    '& li': {
      padding: 0,
    },
    '& .MuiListItemButton-root': {
      padding: theme.spacing(1),
    },
    '& .MuiListItemIcon-root': {
      minWidth: 42,
    },
  },
  collapse: {
    '& .MuiContainer-root': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
}))
