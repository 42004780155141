import { useStyles } from './ServiceVisitList.style'
import { ServiceVisit as ServiceVisitType } from '@alexandrainst/plana-react-api'
import { PositionedList } from '../PositionedList'
import { ServiceVisit } from '../ServiceVisit/ServiceVisit'

export type ServiceVisitListProps = {
  visits: ServiceVisitType[]
  legacyLayout?: boolean
  scale?: number
  useGutter?: boolean
}

export const ServiceVisitList = ({
  visits,
  scale,
  legacyLayout,
  useGutter,
}: ServiceVisitListProps) => {
  const { classes } = useStyles()
  const items = visits.map(it => ({
    position: it.data.from,
    size: it.data.duration,
    item: it,
  }))
  const gutterItems = items.map(it => {
    return { ...it }
  })

  const itemRenderer = (item: ServiceVisitType) => <ServiceVisit visit={item} />
  const gutterItemRenderer = () => (
    <div className={classes.road}>
      <wbr />
    </div>
  )
  return (
    <div>
      <div className={classes.container}>
        {useGutter && (
          <div className={classes.gutter}>
            <PositionedList
              scale={scale}
              legacyGrouping={legacyLayout}
              items={gutterItems}
              itemRenderer={gutterItemRenderer}
            />
            {/* <div className={classes.gutterElement} style={{ top: scale * (7.5 * 60), height: scale * 25 }}>
              <div>ROAD</div>
            </div> */}
          </div>
        )}
        <div className={classes.visitsContainer}>
          <PositionedList
            scale={scale}
            legacyGrouping={legacyLayout}
            items={items}
            itemRenderer={itemRenderer}
          />
        </div>
      </div>
    </div>
  )
}
