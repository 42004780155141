import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import type { RootState } from '@/app'
import { create } from '@alexandrainst/webauthn-pin-storage'

export type AuthState = {
  processing: 'idle' | 'pending'
  userHasMadeBiometricChoice: boolean
  usersBiometricSecretChoice: boolean
  biometricSecretId: string
}

const initialState: AuthState = {
  processing: 'idle',
  usersBiometricSecretChoice: false,
  biometricSecretId: '',
  userHasMadeBiometricChoice: false,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    useBiometrics: (state, action: PayloadAction<boolean>) => {
      state.usersBiometricSecretChoice = action.payload
      state.userHasMadeBiometricChoice = true
    },
    biometricSecretStored: (state, action: PayloadAction<string>) => {
      state.biometricSecretId = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(storeBiometricSecret.fulfilled, state => {
      state.processing = 'idle'
    })
    builder.addCase(storeBiometricSecret.pending, state => {
      state.processing = 'pending'
    })
    builder.addCase(storeBiometricSecret.rejected, state => {
      state.processing = 'idle'
    })
  },
})

const storeBiometricSecret = createAsyncThunk(
  'core/storeBiometricSecret',
  async (secret: string, { dispatch }) => {
    const { biometricSecretStored } = actions
    const secretId = await create(secret, 'PlanA', 'PlanA')
    if (secretId) {
      dispatch(biometricSecretStored(secretId))
    }
  },
  {
    condition: (_, { getState }) =>
      selectAuth(getState() as RootState).processing === 'idle',
  }
)

export const actions = { ...authSlice.actions, storeBiometricSecret }
export const selectAuth = createSelector(
  (state: RootState) => state,
  (state: RootState) => state.auth
)

export default authSlice.reducer
