import { ShellState } from './shellSlice'

export interface ShellStateV4 {
  focus: 'planning' | 'dayRoutes' | 'singleRoute' | 'config'
}

export interface ShellStateV12 {
  focus: 'planning' | 'dayRoutes' | 'singleRoute' | 'config' | 'splash'
  firstLaunchToday: boolean
  timers: {
    timeSinceLastLaunch: number
    lastLaunched: string
  }
}

export const migrations = {
  5: (state: ShellStateV4): ShellStateV12 => {
    return {
      ...state,
      firstLaunchToday: true,
      timers: {
        timeSinceLastLaunch: 0,
        lastLaunched: new Date(0).toUTCString(),
      },
    }
  },
  13: (state: ShellStateV12): ShellState => {
    return {
      ...state,
      previousFocus: state.focus,
    }
  },
}
