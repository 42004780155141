import { SxProps } from '@mui/material'
import { Theme } from '@mui/system'
import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()({
  warning: { position: 'absolute', bottom: 0, right: 0 },
})

export const tooltip: SxProps<Theme> = {
  bgcolor: 'white',
  color: 'black',
  '& ul, & li': {
    padding: '0 0 0 8px',
    margin: 0,
  },
  boxShadow: 2,
}
