import { useApi, ResourceType } from '@alexandrainst/plana-react-api'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { resourceKeys } from './keys'

export const useUpdateResource = <T extends ResourceType>(type: T) => {
  const { updateResource } = useApi(type)
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateResource,
    onSuccess: newResource => {
      queryClient.setQueryData(
        resourceKeys(type).details(newResource.data.id),
        newResource
      )
    },
  })
}
