import { Customer, Note, ServiceVisit } from '@alexandrainst/plana-react-api'
import { VisitState } from '@/common-util/types'
import { assertUnreachable } from '@alexandrainst/assert-unreachable'
import { calendarModelMapper } from '.'
import { PropertySchemas, getProp, parsedProp } from '@/api/parsing'

type VisitStateColors = {
  new: string
  accepted: string
  cancelled: string
  cancelledBackground: string
  inactive: string
  rejected: string
  completed: string
  unknown: string
  internal: string
}

export const visitStateColors: VisitStateColors = {
  new: 'rgb(230, 230, 0)',
  accepted: 'rgb(140, 210, 225)', // TODO: Fetch from gui_config 'visitcolor_state_Appected' (unless this feature is never used by anyone)
  cancelled: 'rgb(230, 230, 230)',
  cancelledBackground: 'rgb(245, 245, 245)',
  inactive: 'rgb(192, 192, 192)',
  rejected: 'rgb(255, 0, 0)',
  completed: 'rgb(0, 240, 0)',
  unknown: 'rgb(30, 30, 30)',
  internal: 'white',
} as const

class ServiceVisitModel {
  private rotaIdPattern = '([^@]+)@(\\d\\d-\\d\\d-\\d\\d)'
  private visit: ServiceVisit
  private notes: Note[]
  private children: ServiceVisitModel[]
  private state: VisitState

  constructor(visit: ServiceVisit, notes: Note[], children: ServiceVisit[]) {
    this.visit = visit
    this.notes = notes
    this.children = children.map(it => new ServiceVisitModel(it, [], []))
    this.state =
      parsedProp(visit, PropertySchemas.serviceVisit.visitState) ?? 'Accepted'
  }

  getVisit = () => this.visit

  getState = () => this.state

  isInactive = () => {
    if (
      this.state === 'Cancelled' &&
      parsedProp(this.visit, PropertySchemas.serviceVisit.cancelledState) ===
        'Suspended'
    ) {
      return true
    }
    return false
  }

  getStateColor = () => {
    switch (this.state) {
      case 'Completed':
        return visitStateColors.completed
      case 'Accepted':
        return visitStateColors.accepted
      case 'Cancelled':
        return visitStateColors.cancelled
      case 'Rejected':
        return visitStateColors.rejected
      case 'Inactive':
        return visitStateColors.inactive
      case 'New':
        return visitStateColors.new
      case 'Unknown':
        return visitStateColors.unknown
      default:
        return assertUnreachable(this.state)
    }
  }

  isChild = (): boolean =>
    parsedProp(this.visit, PropertySchemas.serviceVisit.familyMember, false) ===
    'Child'

  isParent = (): boolean =>
    parsedProp(this.visit, PropertySchemas.serviceVisit.familyMember, false) ===
    'Parent'

  getChildrenIds = (): string[] =>
    Object.keys(
      parsedProp(this.visit, PropertySchemas.serviceVisit.children, false) ?? {}
    )

  getRouteId = () =>
    getProp(this.visit, 'originRoute') ?? this.visit?.data.route

  getUnseenNotes = (user?: string) =>
    user !== undefined
      ? (this.notes.filter(
          note => !(getProp(note, 'seen_by') ?? '').split(',').includes(user)
        ) ?? [])
      : this.notes

  getDescription() {
    if (this.isParent() && this.children.length > 0) {
      return `${this.generateCombinedDescriptionFromChildren()}`
    }
    return this.visit.data.desc
  }

  getTitle() {
    return this.visit.data.desc
  }

  isInternal() {
    return false
  }

  getRotaDateString(composedId: string | undefined) {
    return this.getMatch(composedId, this.rotaIdPattern, 2)
  }

  getOriginId() {
    const serviceVisitId =
      typeof this.visit === 'string' ? this.visit : this.visit?.data.id
    const origin = this.visit?.data.origin
    const originId = this.getMatch(serviceVisitId, this.rotaIdPattern, 1)
    return origin === 'Calendar' ? serviceVisitId : originId
  }

  getServiceVisitId() {
    return this.getOriginId()
  }

  getRotaId(composedId: string | undefined) {
    this.getMatch(composedId, this.rotaIdPattern, 1)
  }

  private generateCombinedDescriptionFromChildren(): string {
    if (this.isParent()) {
      return this.children.reduce(
        (prev, current, currentIndex) =>
          `${prev}${currentIndex === 0 ? '' : ', '}${current.getDescription()}`,
        ''
      )
    } else {
      return ''
    }
  }

  private getMatch(value: string | undefined, pattern: string, group: number) {
    if (value === undefined) {
      return undefined
    }
    const regex = new RegExp(pattern, 'g')
    const matches = [...value.matchAll(regex)]?.map(m => m[group])
    return matches?.[0]
  }

  getCancellationId = () =>
    parsedProp(this.visit, PropertySchemas.serviceVisit.cancellationId)

  withCustomer = (customer: Customer) =>
    new ServiceVisitModelWithCustomer(
      this.visit,
      this.notes,
      this.children.map(it => it.getVisit()),
      customer
    )
}

class ServiceVisitModelWithCustomer extends ServiceVisitModel {
  private customer: ReturnType<typeof calendarModelMapper.mapCustomer>

  constructor(
    visit: ServiceVisit,
    notes: Note[],
    children: ServiceVisit[],
    customer: Customer
  ) {
    super(visit, notes, children)
    this.customer = calendarModelMapper.mapCustomer(customer)
  }

  getDescription() {
    return this.customer.isInternalCustomer()
      ? this.customer.getCustomerName()
      : super.getDescription()
  }

  getTitle() {
    return this.customer.isInternalCustomer()
      ? super.getDescription()
      : this.customer.getCustomerName()
  }

  isInternal() {
    return this.customer.isInternalCustomer()
  }
}

export const removeChildrenFilter = (serviceVisit: ServiceVisit): boolean =>
  !serviceVisitModel(serviceVisit).isChild()

export const serviceVisitModel = (
  visit: ServiceVisit,
  notes: Note[] = [],
  children: ServiceVisit[] = [],
  customer?: Customer
) => {
  const visitModel = new ServiceVisitModel(visit, notes ?? [], children ?? [])
  if (customer !== undefined) {
    return visitModel.withCustomer(customer)
  }
  return visitModel
}
