import { selectors as coreSelectors } from '@/features/core'
import { selectors } from '@/shell'
import { ExpandMore } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from '@mui/material'
import { useAuthentication } from '@alexandrainst/plana-react-api'
import { useAppSelector } from '@/app'
import { useServerLog } from '@/api/hooks'

interface Props {
  show: boolean
}

export const DebugStateView: React.FC<Props> = ({ show }: Props) => {
  const { logConfig } = useServerLog()
  const auth = useAuthentication()
  const core = useAppSelector(coreSelectors.selectCore)
  const shell = useAppSelector(selectors.selectShell)
  if (!show) {
    return null
  }
  return (
    <Box sx={{ width: '100%' }}>
      <StateAccordion title="Authentication" state={auth} />
      <StateAccordion title="Core" state={core} />
      <StateAccordion title="Shell" state={shell} />
      <Button onClick={logConfig}>Report app state</Button>
    </Box>
  )
}

interface StateAccordionProps {
  title: string
  state: unknown
}
const StateAccordion = ({ title, state }: StateAccordionProps) => (
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMore />}>
      <Typography>{title}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Typography>
        <pre>{JSON.stringify(state, undefined, 2)}</pre>
      </Typography>
    </AccordionDetails>
  </Accordion>
)
