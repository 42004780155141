import { useAppDispatch, useAppSelector } from '@/app'
import { Fab } from '@mui/material'
import ZoomInIcon from '@mui/icons-material/Add'
import ZoomOutIcon from '@mui/icons-material/Remove'
import { useCalendarActions, useCalendarSelectors } from '../Calendar'

interface Props {
  minZoom?: number
  maxZoom?: number
}

export const ZoomController = ({ minZoom = 1, maxZoom = 40 }: Props) => {
  const actions = useCalendarActions()
  const { selectScale } = useCalendarSelectors()
  const scale = useAppSelector(selectScale)
  const dispatch = useAppDispatch()

  const scale2zoom = (scale: number) => Math.log2(scale)
  const zoom2scale = (zoom: number) => Math.pow(2, zoom)

  const handleZoomIn = () => {
    dispatch(
      actions.setScale(Math.min(maxZoom, zoom2scale(scale2zoom(scale) + 1)))
    )
  }
  const handleZoomOut = () => {
    dispatch(
      actions.setScale(Math.max(minZoom, zoom2scale(scale2zoom(scale) - 1)))
    )
  }

  return (
    <div>
      <Fab
        disabled={scale >= maxZoom}
        onClick={handleZoomIn}
        size="small"
        color="primary"
        aria-label="zoom in"
        sx={{
          position: 'absolute',
          left: 'auto',
          right: 5,
          bottom: 120,
          top: 'auto',
          margin: 0,
        }}
      >
        <ZoomInIcon />
      </Fab>
      <Fab
        disabled={scale <= minZoom}
        onClick={handleZoomOut}
        size="small"
        color="primary"
        aria-label="zoom out"
        sx={{
          position: 'absolute',
          left: 'auto',
          right: 5,
          bottom: 70,
          top: 'auto',
          margin: 0,
        }}
      >
        <ZoomOutIcon />
      </Fab>
    </div>
  )
}
