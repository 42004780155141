import { useInstallation } from '@/features/app-information/hooks'
import AndroidIcon from '@mui/icons-material/Android'
import AppleIcon from '@mui/icons-material/Apple'
import ComputerIcon from '@mui/icons-material/Computer'
import AppShortcutIcon from '@mui/icons-material/AppShortcut'
import AppBlockingIcon from '@mui/icons-material/AppBlocking'

export const InstallationStatus = () => {
  const { platform, installed } = useInstallation()
  const platformIcon =
    platform === 'android' ? (
      <AndroidIcon />
    ) : platform === 'ios' ? (
      <AppleIcon />
    ) : (
      <ComputerIcon />
    )
  const installedIcon = installed ? <AppShortcutIcon /> : <AppBlockingIcon />
  return (
    <>
      {platformIcon}
      {installedIcon}
    </>
  )
}
