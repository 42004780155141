import { Resource } from '@alexandrainst/plana-react-api'

export * as types from './types'
export { isDefined } from './type-guards'

export const range = (lowEnd: number, highEnd: number) => {
  const arr = []
  let c = highEnd - lowEnd + 1
  while (c--) {
    arr[c] = highEnd--
  }
  return arr
}

/**
 * Convert decimal values to hex string
 * @param decimal number to convert
 * @returns the specified decimal value as a hex string
 */
export function decimalToHexTwosComplement(decimal: number) {
  const size = 6

  if (decimal >= 0) {
    let hexadecimal = decimal.toString(16)

    while (hexadecimal.length % size !== 0) {
      hexadecimal = '' + 0 + hexadecimal
    }

    return hexadecimal
  } else {
    let hexadecimal = Math.abs(decimal).toString(16)
    while (hexadecimal.length % size !== 0) {
      hexadecimal = '' + 0 + hexadecimal
    }

    let output = ''
    let i = 0
    for (i = 0; i < hexadecimal.length; i++) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      output += (0x0f - parseInt(hexadecimal[i]!, 16)).toString(16)
    }

    output = (0x01 + parseInt(output, 16)).toString(16)
    return output
  }
}

export const propColor = (r: Resource): string => {
  const color = JSON.parse(r.data.properties).color ?? 0
  const hex = decimalToHexTwosComplement(color)
  return '#' + hex
}

export const errorMessage = (e: unknown) => {
  if (typeof e === 'string') {
    return e
  } else if (e instanceof Error) {
    return e.message
  } else {
    return `Unknown error: ${JSON.stringify(e)}`
  }
}

export const onlyLettersAndNumbers = (input: string) =>
  input && /^[A-Za-z0-9]*$/.test(input)

export const stripTrailingSlash = (str: string) => {
  return str.endsWith('/') ? str.slice(0, -1) : str
}

export const delay = (ms: number): Promise<void> =>
  new Promise((resolve): void => {
    setTimeout(resolve, ms)
  })
