import { CalendarState } from './calendarViewSlice'

type CalendarStateV13 = {
  date: string
  visitEditorOpen: boolean
  selectedVisit: string
  selectedGroup: string
  selectedRoutes: string[]
  legacyGrouping: boolean
  scale: number
  gutterInterval: number
}

export const migrations = {
  14: (state: CalendarStateV13): CalendarState => {
    return {
      ...state,
      moveVisit: {
        state: 'idle',
        visitId: '',
        sourceRoute: '',
        sourceFrom: 0,
        targetRoute: '',
        targetFrom: 0,
      },
      mode: 'editing',
    }
  },
} as const
