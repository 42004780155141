import { Backdrop, CircularProgress } from '@mui/material'
import { useStyles } from './SplashScreen.style'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@/app'
import { actions, selectors } from '@/shell'

export const SplashScreen = () => {
  const { classes } = useStyles()
  const dispatch = useAppDispatch()
  const previousFocus = useAppSelector(selectors.selectPreviousFocus)

  useEffect(() => {
    const timer = setTimeout(
      () => dispatch(actions.setFocus(previousFocus)),
      1000
    )
    return () => clearTimeout(timer)
  }, [dispatch, previousFocus])

  return (
    <div className={classes.container}>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
}
