import { groupByOverlap } from './groupByOverlap'
import { useStyles } from './PositionedList.style'
export interface ListItem<T> {
  position: number
  size: number
  item: T
}
export interface ListProps<T> {
  items: Array<ListItem<T>>
  itemRenderer: (item: T) => React.ReactNode
  scale?: number
  legacyGrouping?: boolean
}

export const PositionedList = <T,>({
  items,
  itemRenderer,
  scale: scaleInput = 1,
  legacyGrouping = false,
}: ListProps<T>) => {
  const scale = (x: number): number =>
    x * Math.max(0.01, scaleInput === undefined ? 1 : scaleInput)
  const groupedItems = groupByOverlap(items, legacyGrouping)
  const { classes } = useStyles()

  const renderColumn = (
    columnStart: number,
    columnEnd: number,
    column: Array<ListItem<T>>,
    idx: number
  ) => {
    let lastEndPosition = columnStart
    const elements = column.map((it, idx) => {
      const spaceBefore = (
        <div
          key={`space-${idx}`}
          className={classes.space}
          style={{ height: scale(Math.max(0, it.position - lastEndPosition)) }}
        />
      )
      lastEndPosition = it.position + it.size
      return [
        spaceBefore,
        <div
          key={`idx_${idx}`}
          style={{
            height: scale(it.size),
          }}
          className={classes.item}
        >
          {itemRenderer(it.item)}
        </div>,
      ]
    })
    const spaceAfter = (
      <div
        className={classes.space}
        style={{ height: scale(Math.max(0, columnEnd - lastEndPosition)) }}
      />
    )
    return (
      <div
        key={`col-${columnStart}-${columnEnd}-${column[0]?.size ?? 0}-${idx}`}
        className={classes.column}
        style={{ height: scale(columnStart - columnEnd) }}
      >
        {elements}
        {spaceAfter}
      </div>
    )
  }

  const renderGroup = (group: [Array<ListItem<T>>]) => {
    const groupStart =
      group.length > 0 && group[0].length > 0 ? (group[0][0]?.position ?? 0) : 0
    const groupEnd = group
      .flat()
      .reduce((acc, it) => Math.max(acc, it.position + it.size), 0)
    return group.map((column, idx) =>
      renderColumn(groupStart, groupEnd, column, idx)
    )
  }

  const renderGroupItems = (items: Array<Array<Array<ListItem<T>>>>) => {
    return items.map((group, idx) => {
      const groupStartPosition = (group[0] ?? [])[0]?.position ?? 0
      return (
        <div
          key={idx}
          className={classes.group}
          style={{ position: 'absolute', top: scale(groupStartPosition) }}
        >
          {renderGroup(group as [Array<ListItem<T>>])}
        </div>
      )
    })
  }

  return (
    <div className={classes.list}>
      <div className={classes.listContent}>
        {renderGroupItems(groupedItems)}
      </div>
    </div>
  )
}
