import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()({
  headerContainer: {
    position: 'sticky',
    top: '0px',
    zIndex: 10,
    display: 'flex',
    opacity: '85%',
  },
  headerElement: {
    flex: 1,
    marginLeft: '1px',
    marginRight: '1px',
  },
})
