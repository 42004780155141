import { useResource, useResourcesBySource } from '@/api'
import { useAppSelector } from '@/app'
import { selectors as coreSelectors } from '@/features/core'
import { removeChildrenFilter } from '@/features/calendar/domain/serviceVisitModel'
import {
  ServiceVisitList,
  ServiceVisitListProps,
} from '@/features/calendar/components/ServiceVisitList'
import { calendarModelMapper } from '@/features/calendar/domain'
import { useDroppable } from '@dnd-kit/core'
import { useCalendarSelectors } from '../../Calendar'
import { produce } from 'immer'

export interface RouteProps extends Partial<ServiceVisitListProps> {
  title?: boolean
  id: string
  date: string
}

export const Route = ({
  legacyLayout = false,
  scale = 1,
  id,
  date,
}: RouteProps) => {
  const { setNodeRef, isOver, active } = useDroppable({
    id,
    data: {
      accepts: ['visit'],
      routeId: id,
    },
  })

  const { selectMoveVisitForRoute } = useCalendarSelectors()
  const moveVisit = useAppSelector(state => selectMoveVisitForRoute(state, id))
  const movedVisit = useResource('ServiceVisit', moveVisit?.visitId)

  const { hideInactiveVisits } = useAppSelector(coreSelectors.selectFlags)

  const { data: serviceVisits } = useResourcesBySource(
    'ServiceVisit',
    'Route',
    id,
    date,
    date,
    {
      select: data =>
        data
          .filter(it => it.data.state !== 'Deleted')
          .filter(it => it.data.route === id)
          .filter(it =>
            hideInactiveVisits
              ? !calendarModelMapper.mapServiceVisit(it).isInactive()
              : true
          )
          .filter(removeChildrenFilter)
          .filter(v =>
            !active &&
            v.data.id === moveVisit?.visitId &&
            v.data.route !== moveVisit?.targetRoute
              ? false
              : true
          ),
    }
  )

  const visits = (serviceVisits ?? [])
    .filter(
      v =>
        !(
          movedVisit.data &&
          moveVisit?.targetRoute === id &&
          v.data.id === moveVisit?.visitId
        )
    )
    .map(v =>
      !active &&
      v.data.id === moveVisit?.visitId &&
      v.data.from !== moveVisit?.targetFrom
        ? produce(v, draft => {
            draft.data.from = moveVisit.targetFrom
          })
        : v
    )
    .concat(
      movedVisit.data && moveVisit?.targetRoute === id
        ? [
            produce(movedVisit.data, draft => {
              draft.data.from = moveVisit.targetFrom
            }),
          ]
        : []
    )

  return (
    <div
      style={{
        zIndex: 999,
        height: '100%',
        width: '100%',
        backgroundColor: isOver ? 'rgba(0,0,0,0.25)' : 'transparent',
      }}
      ref={setNodeRef}
    >
      <ServiceVisitList
        visits={visits}
        legacyLayout={legacyLayout}
        scale={scale}
      />
    </div>
  )
}
