import { useResource } from '@/api'
import { calendarModelMapper } from '@/features/calendar/domain'

export const useCustomerModel = (customerId: string | undefined) => {
  const {
    data: customer,
    isLoading,
    isSuccess,
  } = useResource('Customer', customerId)

  const customerModel =
    customer !== undefined
      ? calendarModelMapper.mapCustomer(customer)
      : undefined

  return {
    customerModel,
    isLoading,
    isSuccess,
  }
}
