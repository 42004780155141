import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()({
  container: {
    padding: '5px',
    overflow: 'clip',
    height: 'calc(100dvh - 116px)',
  },
  routeContainer: {
    position: 'relative',
    overflow: 'clip',
    overflowY: 'scroll',
    overflowX: 'clip',
    height: '100%',
    width: '100%',
  },
  controlsContainer: {
    display: 'flex',
  },
})
