import { useResource } from '@/api'
import { calendarModelMapper } from '@/features/calendar/domain'

export const useRouteModel = (routeId: string | undefined) => {
  const { data: route, isLoading, isSuccess } = useResource('Route', routeId)

  const routeModel =
    route !== undefined ? calendarModelMapper.mapRoute(route) : undefined

  return {
    routeModel,
    isLoading,
    isSuccess,
  }
}
