import { PropertySchemas, parsedProp } from '@/api/parsing'
import { useServiceVisitData } from '@/features/calendar/components/ServiceVisit/hooks/useServiceVisitData'
import {
  ServiceVisit as ServiceVisitType,
  useAuthentication,
} from '@alexandrainst/plana-react-api'
import { ServiceVisitRenderer } from './ServiceVisitRenderer'
import { visitStateColors } from '@/features/calendar/domain/serviceVisitModel'
import { useDraggable } from '@dnd-kit/core'

export interface ServiceVisitProps {
  visit: ServiceVisitType
  renderHeight?: number
}
export const ServiceVisit = ({ visit, renderHeight }: ServiceVisitProps) => {
  const {
    state: { externalSystemId: me },
  } = useAuthentication()

  const {
    title,
    routeColor,
    stateColor,
    isInternal,
    unseenNotes,
    state,
    description,
    violationsTooltipText,
    isSelected,
    selectVisit,
  } = useServiceVisitData(visit, me)

  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: visit.data.id,
    data: { visit, type: 'visit' },
  })

  const adhocDesc = parsedProp(visit, PropertySchemas.serviceVisit.adhocDesc)
  return (
    <ServiceVisitRenderer
      ref={setNodeRef}
      date={visit.data.date ?? ''}
      from={visit.data.from}
      duration={visit.data.duration}
      title={title}
      description={description ?? ''}
      backgroundColor={
        isDragging
          ? 'rgba(0.75, 0.75, 0.75, 0.5)'
          : state === 'Cancelled'
            ? visitStateColors.cancelledBackground
            : isInternal
              ? visitStateColors.internal
              : routeColor
      }
      textColor={state === 'Cancelled' ? visitStateColors.inactive : 'black'}
      sidebarColor={stateColor}
      markedSelected={isSelected}
      notes={unseenNotes}
      onClick={selectVisit}
      adhocDesc={adhocDesc}
      violationsTooltipText={violationsTooltipText}
      renderHeight={renderHeight}
      {...listeners}
      {...attributes}
    />
  )
}
