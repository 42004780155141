import {
  useApi,
  ResourceType,
  getItems,
  TypedResource,
} from '@alexandrainst/plana-react-api'
import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'
import { CachedResourceList, detailsCacheUpdater } from './helpers'
import { resourceKeys } from './keys'

export const useResources = <T extends ResourceType>(
  type: T,
  onlyLatest?: boolean,
  options?: Partial<
    UseQueryOptions<
      CachedResourceList<T>,
      unknown,
      TypedResource<T>[],
      ReturnType<ReturnType<typeof resourceKeys<T>>['lists']>
    >
  >
) => {
  const { fetchResources } = useApi(type)
  const queryClient = useQueryClient()
  return useQuery({
    queryKey: resourceKeys(type).lists(),
    queryFn: async ({ queryKey, signal }) => {
      const cache: CachedResourceList<T> | undefined =
        queryClient.getQueryData(queryKey)
      let since = 0
      if (cache !== undefined && cache.currentVersion) {
        since = cache.currentVersion
      } else if (onlyLatest) {
        const tmp = await fetchResources(0, 1, { fetchOptions: { signal } })
        since = tmp.data.currentVersion ?? 0
      }
      const result = await fetchResources(since, undefined, {
        fetchOptions: { signal },
      })

      const items = getItems(result)
      const receivedData = items.length > 0

      items.forEach((it: TypedResource<T>) => {
        queryClient.setQueryData(
          resourceKeys(type).details(it.data.id),
          detailsCacheUpdater(it)
        )
      })

      if (receivedData) {
        await queryClient.invalidateQueries({
          queryKey: resourceKeys(type).bySourceScope(),
        })
      }
      const nextSince = items.reduce(
        (prev, curr) => Math.max(prev, curr.data.timestamp_m),
        since
      )

      if (since > 0 && cache !== undefined) {
        return {
          type,
          currentVersion: nextSince,
          sinceVersion: result.data.sinceVersion ?? 0,
          items: mergeCacheWithResult(cache.items, items),
        }
      } else {
        return {
          type,
          currentVersion: nextSince,
          sinceVersion: result.data.sinceVersion ?? 0,
          items,
        }
      }
    },
    ...options,
    select: data => data.items,
  })
}

function mergeCacheWithResult<T extends ResourceType>(
  cache: TypedResource<T>[],
  result: TypedResource<T>[]
): TypedResource<T>[] {
  const mergedItems = [...cache]
  const newItems = [...result]

  newItems.forEach(newItem => {
    const idx = mergedItems.findIndex(
      oldItem => newItem.data.id === oldItem.data.id
    )
    if (idx === -1) {
      mergedItems.push(newItem)
    } else if (mergedItems[idx]?.data.version ?? 0 < newItem.data.version) {
      mergedItems[idx] = newItem
    }
  })
  return mergedItems
}
