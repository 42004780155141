import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()({
  item: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
    display: 'flex',
    overflow: 'hidden',
  },
  list: {},
  listContent: {
    position: 'relative',
    color: 'stroke',
    height: '100%',
  },
  listHeader: {
    backgroundColor: 'blue',
    color: 'white',
  },
  group: {
    width: '100%',
    whiteSpace: 'nowrap',
    display: 'flex',
    flexWrap: 'wrap',
    // backgroundColor: 'blue',
  },
  space: {
    // backgroundColor: 'green',
    // border: 'solid',
  },
  column: {
    // backgroundColor: 'yellow',
    flex: '1',
    overflow: 'hidden',
  },
})
