import {
  useApi,
  ResourceType,
  getItems,
  TypedResource,
} from '@alexandrainst/plana-react-api'
import {
  useQueries,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'
import { resourceKeys } from './keys'
import { useResources } from './useResources'
import { detailsCacheUpdater } from './helpers'
import { useServerLog } from '@/api/hooks/useServerLog'

export const useResourcesBySource = <T extends ResourceType>(
  type: T,
  sourceType: ResourceType,
  sourceId: string,
  from: string,
  to: string,
  options?: Partial<
    UseQueryOptions<
      TypedResource<T>[],
      unknown,
      TypedResource<T>[],
      ReturnType<ReturnType<typeof resourceKeys<T>>['bySource']>
    >
  >
) => {
  const { error: logError } = useServerLog()
  const { fetchResourcesBySource } = useApi(type)
  const { error } = useResources(type, true, { refetchInterval: 1000 })
  if (error) {
    logError(`Error updating resources in background ${type} error=${error}`)
  }
  const queryClient = useQueryClient()

  return useQuery({
    queryKey: resourceKeys(type).bySource(sourceType, sourceId, from, to),
    queryFn: async ({
      queryKey: [{ sourceType, sourceId, from, to }],
      signal,
    }) => {
      const collection = await fetchResourcesBySource(
        sourceType,
        sourceId,
        from,
        to,
        { fetchOptions: { signal } }
      )
      const data = getItems(collection)
      data.forEach((it: TypedResource<T>) => {
        queryClient.setQueryData(
          resourceKeys(type).details(it.data.id),
          detailsCacheUpdater(it)
        )
      })
      return data
    },
    ...options,
    enabled:
      typeof sourceId === 'string' &&
      sourceId !== undefined &&
      sourceId !== '' &&
      from !== '' &&
      to !== '',
    //refetchInterval: 30000,
  })
}

export const useResourcesBySourceBulk = <T extends ResourceType>(
  type: T,
  sourceType: ResourceType,
  sourceIds: string[],
  from: string,
  to: string,
  options?: Partial<
    UseQueryOptions<
      TypedResource<T>[],
      unknown,
      TypedResource<T>[],
      ReturnType<ReturnType<typeof resourceKeys<T>>['bySource']>
    >
  >
) => {
  const { error: logError } = useServerLog()
  const { fetchResourcesBySource } = useApi(type)
  const { error } = useResources(type, true, {
    refetchInterval: 1000,
    enabled: sourceIds.length > 0,
  })
  if (error) {
    logError(`Error updating resources in background ${type} error=${error}`)
  }
  const queryClient = useQueryClient()

  return useQueries({
    queries: sourceIds.map(sourceId => {
      return {
        queryKey: resourceKeys(type).bySource(sourceType, sourceId, from, to),
        queryFn: async () => {
          const collection = await fetchResourcesBySource(
            sourceType,
            sourceId,
            from,
            to
          )
          return getItems(collection)
        },
        ...options,
        enabled:
          (options?.enabled ?? true) &&
          typeof sourceId === 'string' &&
          sourceId !== undefined &&
          sourceId !== '' &&
          from !== '' &&
          to !== '',
        onSuccess(data: TypedResource<T>[]) {
          data.forEach((it: TypedResource<T>) => {
            queryClient.setQueryData(
              resourceKeys(type).details(it.data.id),
              detailsCacheUpdater(it)
            )
          })
        },
        //refetchInterval: 30000,
      }
    }),
    combine: q => q.map(it => ({ ...it })),
  })
}
