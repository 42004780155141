import { useAppDispatch, useAppSelector } from '@/app'
import { useGitInformation } from '@/features/app-information'
import { logToServer, selectors as coreSelectors } from '@/features/core'
import { selectors as shellSelectors } from '@/shell'
import {
  LogMessage,
  useAuthentication,
  useClient,
} from '@alexandrainst/plana-react-api'

const emptyLog = {
  id: '',
  lvl: 'info',
  subsystem: 'web',
  time: '',
  version: '',
  msg: '',
} as const

/**
 * Provides methods for generating log messages that are send to the backend server.
 * @returns config logger and loggers for info, warn and error
 */
export const useServerLog = () => {
  const dispatch = useAppDispatch()
  const core = useAppSelector(coreSelectors.selectCore)
  const shell = useAppSelector(shellSelectors.selectShell)
  const {
    state: { userid },
  } = useAuthentication()
  const { version, branch } = useGitInformation()
  const client = useClient()

  const createLog = (msg: string, level: LogMessage['lvl']) => {
    return {
      ...emptyLog,
      id: userid,
      time: `${Date.now()}`,
      msg,
      version: `${version}[${branch}]`,
      lvl: level,
    }
  }

  const sendLog = (messages: LogMessage[]) => {
    return dispatch(logToServer({ client, messages }))
  }

  return {
    logConfig: () =>
      sendLog([
        createLog(JSON.stringify(core), 'info'),
        createLog(JSON.stringify(shell), 'info'),
      ]),
    info: (msg: string) => sendLog([createLog(msg, 'info')]),
    warn: (msg: string) => sendLog([createLog(msg, 'warning')]),
    error: (msg: string) => sendLog([createLog(msg, 'error')]),
  }
}
