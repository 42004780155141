import { Avatar, Box, Button, Container, FormGroup, Stack } from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'
import { FeatureToggle } from './FeatureToggle'
import { useStyles } from './ConfigMainView.style'
import { DebugStateView } from '@/app/DebugStateView'
import { ReportErrorButton } from './ReportErrorButton'
import { ServiceWorkerStatus } from '@/features/pwa'
import { LanguageSwitcher } from '@/features/i18n'
import { useDebug } from './useDebug'
import { useQueryClient } from '@tanstack/react-query'
import { Trans, useTranslation } from 'react-i18next'
import { useAuthentication } from '@alexandrainst/plana-react-api'
import { useOpenFeatureClient } from '@/incubator/featureFlags/FeatureFlagProvider'
import { Copyright } from '@/features/app-information/Copyright'
import { InstallationStatus } from '@/features/app-information/InstallationStatus'
import { VersionInformation } from '@/features/app-information/VersionInformation'

export const ConfigMainView = () => {
  const { classes } = useStyles()
  const { debug, toggleDebug } = useDebug()
  const { logout } = useAuthentication()
  const { t } = useTranslation()
  const client = useQueryClient()
  const clear = () => client.clear()
  const featureClient = useOpenFeatureClient()

  const settingsIcon = featureClient.getBooleanValue('allowDebug', false) ? (
    <Button onClick={toggleDebug}>
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <SettingsIcon />
      </Avatar>
    </Button>
  ) : (
    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
      <SettingsIcon />
    </Avatar>
  )

  return (
    <Container component="main" maxWidth="xs" className={classes.container}>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {settingsIcon}
        <FormGroup>
          <FeatureToggle feature="singleRouteMode" />
          <FeatureToggle feature="useActualTime" />
          <FeatureToggle feature="automaticLogout" />
        </FormGroup>
        {debug && (
          <FormGroup>
            <FeatureToggle feature="showDayRoutes" />
            <FeatureToggle feature="useBurgerMenu" />
            <FeatureToggle feature="useZod" />
            <FeatureToggle feature="hideInactiveVisits" />
          </FormGroup>
        )}

        <DebugStateView show={debug} />
        <LanguageSwitcher />
        <Stack direction="row">
          <InstallationStatus />
          <ServiceWorkerStatus />
        </Stack>
        <Copyright sx={{ mt: 2, mb: 2 }} />
        <VersionInformation />
        <ReportErrorButton />
        <Button
          sx={{ mt: 2, mb: 2 }}
          color="inherit"
          variant="outlined"
          onClick={() => clear()}
        >
          {t('shell.header.clearCache')}
        </Button>
        <Button
          sx={{ mt: 2, mb: 2 }}
          color="inherit"
          variant="outlined"
          onClick={() => logout()}
        >
          <Trans t={t} i18nKey="shell.buttons.signOut">
            Sign out
          </Trans>
        </Button>
      </Box>
    </Container>
  )
}
