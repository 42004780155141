import { createSlice } from '@reduxjs/toolkit'

export interface DayRoutesState {
  date: string
}

const initialState: DayRoutesState = {
  date: '',
}

export const dayRoutesSlice = createSlice({
  name: 'shell',
  initialState,
  reducers: {},
})

//export const {} = dayRoutesSlice.actions

export default dayRoutesSlice.reducer
