import { useAppDispatch, useAppSelector } from '@/app'
import { FormControlLabel, Switch } from '@mui/material'
import { useCalendarActions, useCalendarSelectors } from '@/features/calendar'

export const LegacyGroupingController = () => {
  const actions = useCalendarActions()
  const { selectLegacyGrouping } = useCalendarSelectors()
  const legacyGrouping = useAppSelector(selectLegacyGrouping)
  const dispatch = useAppDispatch()
  return (
    <FormControlLabel
      control={
        <Switch
          checked={legacyGrouping}
          onChange={() => dispatch(actions.setLegacyGrouping(!legacyGrouping))}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      }
      label="Legacy"
      labelPlacement="top"
    />
  )
}
