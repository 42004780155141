import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()({
  container: {
    display: 'flex',
    position: 'relative',
    height: '100%',
  },
  gutter: {
    width: '6ch',
  },
  gutterElement: {
    position: 'absolute',
    backgroundColor: 'gray',
    width: '6ch',
    height: '10px',
  },
  visitsContainer: {
    flex: '1 1 auto',
  },
  road: {
    backgroundColor: 'gray',
  },
})
