import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import ClearIcon from '@mui/icons-material/Clear'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { Copyright } from '@/features/app-information/Copyright/Copyright'
import { useTranslation } from 'react-i18next'
import { LanguageSwitcher } from '@/features/i18n'
import { DebugStateView } from '@/app'
import { SystemIdentifier } from '@/features/core'
import { IconButton } from '@mui/material'
import { VersionInformation } from '@/features/app-information/VersionInformation'

type SignInViewProps = {
  onSignIn: (username: string, password: string) => Promise<void>
  onSigninWithBiometrics?: () => Promise<void>
  onSetBiometricsChoice?: (choice: boolean) => void
  onReset?: () => void
  loginRequestInProgress: boolean
  showRemember?: boolean
  showBiometricsButton?: boolean
  showBiometricsPrompt?: boolean
}

export const SignInView = ({
  onSignIn,
  onSigninWithBiometrics,
  onSetBiometricsChoice = () => void 0,
  onReset = () => void 0,
  loginRequestInProgress,
  showRemember = false,
  showBiometricsButton = false,
  showBiometricsPrompt = false,
}: SignInViewProps) => {
  const { t } = useTranslation()
  const [showState, setShowState] = React.useState(false)

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    onSignIn(
      data.get('username')?.toString() ?? '',
      data.get('password')?.toString() ?? ''
    )
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Button onClick={() => setShowState(!showState)}>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
        </Button>
        <Typography component="h1" variant="h5">
          {t('signInView.signIn')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            ml: 1,
          }}
        >
          <SystemIdentifier sx={{ color: 'text.secondary' }} />
          <IconButton size="small" onClick={() => onReset()}>
            <ClearIcon fontSize="inherit" />
          </IconButton>
        </Box>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            disabled={showBiometricsPrompt}
            margin="normal"
            required
            fullWidth
            id="username"
            label={t('signInView.username')}
            name="username"
            autoComplete="username"
            autoFocus
          />
          <TextField
            disabled={showBiometricsPrompt}
            margin="normal"
            required
            fullWidth
            name="password"
            label={t('signInView.password')}
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {showRemember && (
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label={t('signInView.rememberMe')}
            />
          )}
          {!showBiometricsPrompt && (
            <Button
              disabled={loginRequestInProgress}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {t('signInView.signIn')}
            </Button>
          )}
          {showBiometricsButton && (
            <Button
              onClick={onSigninWithBiometrics}
              disabled={loginRequestInProgress}
              fullWidth
              variant="contained"
              sx={{ mt: 1, mb: 2 }}
            >
              {t('signInView.signInWithBiometrics')}
            </Button>
          )}
          {showBiometricsPrompt && (
            <>
              <Button
                variant="contained"
                fullWidth
                disabled={loginRequestInProgress}
                onClick={() => onSetBiometricsChoice(true)}
                sx={{ mt: 3, mb: 2 }}
              >
                {t('signInView.storeUsingBiometrics')}
              </Button>
              <Button
                fullWidth
                disabled={loginRequestInProgress}
                onClick={() => onSetBiometricsChoice(false)}
                sx={{ mt: 1, mb: 2 }}
              >
                {t('signInView.goToApp')}
              </Button>
            </>
          )}
        </Box>
        <LanguageSwitcher />
        <Copyright sx={{ mt: 8, mb: 4 }} />
        <VersionInformation />
        <DebugStateView show={showState} />
      </Box>
    </Container>
  )
}
