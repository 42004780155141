import { useResources } from '@/api'
import { useAppDispatch, useAppSelector } from '@/app'
import { getProp } from '@/api/parsing'
import { useCalendarActions, useCalendarSelectors } from '@/features/calendar'
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

export const RouteSelectorController = () => {
  const { t } = useTranslation()
  const actions = useCalendarActions()
  const { selectSelectedRoutes, selectSelectedGroup } = useCalendarSelectors()
  const selectedGroup = useAppSelector(selectSelectedGroup)
  const selectedRoutes = useAppSelector(selectSelectedRoutes)
  const dispatch = useAppDispatch()
  const { data: routes } = useResources('Route')
  const handleSelectRoutes = (e: SelectChangeEvent<typeof selectedRoutes>) => {
    const {
      target: { value },
    } = e
    if (value.includes('all')) {
      dispatch(actions.selectRoutes([]))
    } else {
      dispatch(
        actions.selectRoutes(typeof value === 'string' ? [value] : value)
      )
    }
  }
  const routesInSelectedGroup = routes?.filter(
    r => (getProp(r, 'groups') ?? []).indexOf(selectedGroup) !== -1
  )
  return (
    <FormControl sx={{ m: 1, width: 170 }}>
      <InputLabel id="select-routes-label">
        {t('planning.chooseroutes')}
      </InputLabel>
      <Select
        size="small"
        labelId="select-routes-label"
        name="select-routes"
        id="select-routes"
        value={selectedRoutes}
        multiple
        onChange={handleSelectRoutes}
        input={<OutlinedInput label={t('planning.chooseroutes')} />}
        renderValue={selected =>
          selected.length === 0
            ? 'Showing all routes'
            : selected.length === 1
              ? (getProp(
                  routesInSelectedGroup?.find(
                    it => it.data.id === selected[0]
                  ) ?? '',
                  'name'
                ) ?? '')
              : `Showing ${selected.length} routes`
        }
      >
        <MenuItem value="all">{t('common.all')}</MenuItem>
        {[...(routesInSelectedGroup ?? [])]
          .sort((a, b) =>
            (getProp(a.data.properties, 'name') ?? '').localeCompare(
              getProp(b.data.properties, 'name') ?? ''
            )
          )
          .map((route, idx) => (
            <MenuItem key={idx} value={route.data.id}>
              {getProp(route, 'name')}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}
