import { useResource, useResourcesBySource } from '@/api'
import { decimalToHexTwosComplement } from '@/common-util'
import { RouteHeaderRenderer } from './RouteHeaderRenderer'
import dayjs from 'dayjs'
import { activityLogModel } from '@/features/calendar/domain/activityLogModel'
import { PropertySchemas, parsedProp } from '@/api/parsing'

export const RouteHeader = ({ id, date }: { id: string; date: string }) => {
  const { data: route } = useResource('Route', id)
  const { data: activityLogs } = useResourcesBySource(
    'ActivityLog',
    'Route',
    id,
    date,
    date
  )
  const distance = activityLogs
    ?.map(activityLogModel)
    .reduce((p, c) => c.totalTransportInMeters() ?? 0 + p, 0)
  const name = parsedProp(route, PropertySchemas.route.name) ?? ''
  const color = `#${decimalToHexTwosComplement(parsedProp(route, PropertySchemas.route.color) ?? 0)}`
  const myDate = dayjs(date)
  const isLocked =
    myDate.isBefore(dayjs()) ||
    parsedProp(route, PropertySchemas.route.lockedToCalendar)
  const transportMode = parsedProp(route, PropertySchemas.route.transportMode)
  return (
    <RouteHeaderRenderer
      title={name}
      color={color}
      isLocked={isLocked}
      transportMode={transportMode}
      distance={distance}
    />
  )
}
