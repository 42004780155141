import { ServiceVisit } from '@alexandrainst/plana-react-api'
import {
  useResourceIds,
  useViolationsToolTipTextForVisit,
} from '@/features/violations'
import { useResourcesBySourceBulk } from '@/api/hooks/useResourcesBySource'
import { useResource } from '@/api'

/**
 * Note: 'origin' is used as 'currentOwner' because it is the same.
 * See ServiceVisitJSONUtil.java::getResource
 */

export const useVisitViolationsModel = (
  visit: ServiceVisit | undefined,
  visitDescription: string,
  calculatedVisitId?: string
) => {
  const visitDate = visit?.data.date ?? ''
  const resourceIds = useResourceIds(visit, calculatedVisitId)

  // The unused code below is for rota (grundplan) violation. Rota has not been implemented yet.

  // TODO warnings: Handle all origin === 'Rota' cases, see ViolationVisitUtil.java::56
  // TODO warnings: currently including rotaViolations results in 2x same violations, maybe because of
  // todos here and in visitViolationsModel.ts

  // const anchorDate =
  //   parsedProp(visit, PropertySchemas.serviceVisit.anchorDate) ?? ''

  // const rotaViolationQueries = useResourcesBySourceBulk(
  //   'Violation',
  //   'RotaVisit',
  //   resourceIds,
  //   anchorDate,
  //   anchorDate,
  //   {
  //     enabled: anchorDate !== undefined, // && origin === 'Rota'
  //     select: s => s.filter(r => r.data.state !== 'Deleted'),
  //   }
  // )

  // const rotaViolationsNotAnchorDateQueries = useResourcesBySourceBulk(
  //   'Violation',
  //   'RotaVisit',
  //   resourceIds,
  //   visitDate,
  //   visitDate,
  //   {
  //     enabled: visitDate !== anchorDate, // && origin === 'Rota'
  //     select: s => s.filter(r => r.data.state !== 'Deleted'),
  //   }
  // )

  // const rotaViolations = rotaViolationQueries
  //   .concat(rotaViolationsNotAnchorDateQueries)
  //   .flatMap(v => (v.data !== undefined ? v.data : []))

  // TODO warnings: Once grundplan (rota) exists, only do if context is calendar
  // in java: if (context == Origin.calendar) { ... }
  // in ts: add context === Origin.calendar to query "enabled" option
  // const rotaViolationsInCalendarContextQueries = useResourcesBySourceBulk(
  //   'Violation',
  //   'ServiceVisit',
  //   resourceIds,
  //   visitDate,
  //   visitDate,
  //   {
  //     enabled: visitDate !== undefined, // && origin === 'Rota'
  //     select: s => s.filter(r => r.data.state !== 'Deleted'),
  //   }
  // )
  // const rotaViolationsInCalendarContext =
  //   rotaViolationsInCalendarContextQueries.flatMap(v =>
  //     v.data !== undefined ? v.data : []
  //   )

  // const filteredRotaViolations = getFilteredRotaViolations(
  //   rotaViolations,
  //   rotaViolationsInCalendarContext
  // )

  const nonRotaViolationQueries = useResourcesBySourceBulk(
    'Violation',
    'ServiceVisit',
    resourceIds,
    visitDate,
    visitDate,
    {
      enabled: resourceIds.length > 0 && visitDate !== undefined, // && origin !== 'Rota'
      select: s => s.filter(r => r.data.state !== 'Deleted'),
    }
  )

  const nonRotaViolations = nonRotaViolationQueries
    .flatMap(v => (v.data !== undefined ? v.data : []))
    .filter(s => s.data.state !== 'Deleted')

  const { data: serverConfiguration } = useResource(
    'PropertySet',
    'serverconfiguration'
  )

  // const violations = nonRotaViolations.concat(filteredRotaViolations)
  const violations = nonRotaViolations

  return {
    violations,
    tooltipText: useViolationsToolTipTextForVisit(
      serverConfiguration,
      violations,
      visitDescription
    ),
  }
}
