import * as Sentry from '@sentry/react'
import { QueryClient } from '@tanstack/react-query'
import './PlanADesktopApp.css'
import { PlanAProvider, ApiClient } from '@alexandrainst/plana-react-api'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { actions as shellActions } from '@/shell'

import { useAppDispatch, useAppSelector } from '@/app'
import { BackendSelector, selectors as coreSelectors } from '@/features/core'
import { useEffect } from 'react'
import { useInstallation } from '@/features/app-information'
import { useServiceWorker } from '@/features/pwa'
import { MuiLocalizationProvider } from '@/features/i18n/MuiLocalizationProvider'
import { LoginStateHandler } from '@/features/auth'
import '@/dayjs-extensions'
import { DesktopMainShellView } from './DesktopMainShellView'
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material'
import { ApplicationQueryClientProvider } from '@/app/ApplicationQeueryClientProvider'

const theme = createTheme({})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
      refetchInterval: 5000,
      refetchIntervalInBackground: false,
      throwOnError: true,
    },
  },
})

const saveToLocalStorage = (value: string) =>
  localStorage.setItem('plana_credentials', value)
const loadFromLocalStorage = () =>
  localStorage.getItem('plana_credentials') ?? ''
const removeFromLocalStorage = () =>
  localStorage.removeItem('plana_credentials')

const saveToSessionStorage = (value: string) =>
  window.sessionStorage.setItem('plana_credentials', value)
const loadFromSessionStorage = () =>
  window.sessionStorage.getItem('plana_credentials') ?? ''
const removeFromSessionStorage = () =>
  window.sessionStorage.removeItem('plana_credentials')

export const PlanADesktopApp = () => {
  useServiceWorker()

  const dispatch = useAppDispatch()
  const backend = useAppSelector(coreSelectors.selectBackend)
  const useZod = useAppSelector(coreSelectors.selectFlag('useZod'))
  const { installed } = useInstallation()
  const credentialsManager = {
    saveCredentials: installed ? saveToLocalStorage : saveToSessionStorage,
    loadCredentials: installed ? loadFromLocalStorage : loadFromSessionStorage,
    clearCredentials: installed
      ? removeFromLocalStorage
      : removeFromSessionStorage,
  }

  useEffect(() => {
    const onFocus = () => {
      dispatch(shellActions.appLaunched())
    }
    window.addEventListener('focus', onFocus)
    dispatch(shellActions.appLaunched())
    return () => window.removeEventListener('focus', onFocus)
  }, [dispatch])

  if (backend.valid) {
    return (
      <ThemeProvider theme={theme}>
        <div className="App">
          <ApplicationQueryClientProvider queryClient={queryClient}>
            <MuiLocalizationProvider>
              <CssBaseline />
              <PlanAProvider
                client={
                  new ApiClient({
                    host: backend.baseUrl,
                    useZod: useZod,
                    onZodError: e =>
                      Sentry.captureMessage(`Zod error detected ${e}`),
                  })
                }
                offline={true}
                credentialManager={credentialsManager}
              >
                <LoginStateHandler />
                <DesktopMainShellView />
              </PlanAProvider>
            </MuiLocalizationProvider>
          </ApplicationQueryClientProvider>
        </div>
      </ThemeProvider>
    )
  }

  return <BackendSelector />
}

export default PlanADesktopApp
