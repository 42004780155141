import { produce } from 'immer'
import { PersonalPlanState } from './personalPlanSlice'

export const migrations = {
  14: (state: PersonalPlanState): PersonalPlanState => {
    return produce(state, draft => {
      draft.calendar.mode = 'editing'
    })
  },
} as const
