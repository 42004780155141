/* eslint-disable @typescript-eslint/no-explicit-any */

import { migrations as coreMigrations } from '@/features/core/migrations'
import { migrations as shellMigrations } from '@/shell/migrations'
import { migrations as authMigrations } from '@/features/auth/migrations'
import { migrations as calendarMigrations } from '@/features/calendar/migrations'
import { migrations as planningMigrations } from '@/features/planning'
import { migrations as personalPlanMigrations } from '@/features/personal-plan'

const migrateCore = (version: number, state: any) => {
  if (version in coreMigrations) {
    const k = version as keyof typeof coreMigrations
    const migration = coreMigrations[k]
    return migration(state)
  }
  return state
}

const migrateAuth = (version: number, state: any) => {
  if (version in authMigrations) {
    const k = version as keyof typeof authMigrations
    const migration = authMigrations[k]
    return migration(state)
  }
  return state
}

const migrateShell = (version: number, state: any) => {
  if (version in shellMigrations) {
    const k = version as keyof typeof shellMigrations
    const migration = shellMigrations[k]
    return migration(state)
  }
  return state
}

const migrateCalendar = (version: number, state: any) => {
  if (version in calendarMigrations) {
    const k = version as keyof typeof calendarMigrations
    const migration = calendarMigrations[k]
    return migration(state)
  }
  return state
}

const migratePlanning = (version: number, state: any) => {
  if (version in planningMigrations) {
    const k = version as keyof typeof planningMigrations
    const migration = planningMigrations[k]
    return migration(state)
  }
  return state
}

const migratePersonalPlan = (version: number, state: any) => {
  if (version in personalPlanMigrations) {
    const k = version as keyof typeof personalPlanMigrations
    const migration = personalPlanMigrations[k]
    return migration(state)
  }
  return state
}

export const migrations = {
  2: (state: any) => ({
    ...state,
    core: { ...state.core, baseUrl: 'https://plana.alexandra.dk/testserver' },
  }),
  3: (state: any) => ({
    ...state,
    core: {
      ...state.core,
      baseUrl: undefined,
      global: {
        ...state.core.global,
        backendServer: {
          baseUrl: state.core.baseUrl,
          systemId: 'testserver',
          validating: 'idle',
          valid: true,
        },
      },
    },
  }),
  4: (state: any) => {
    const core = coreMigrations[4]
      ? coreMigrations[4](state.core)
      : { ...state.core }
    return { ...state, core }
  },
  5: (state: any) => {
    const shell = shellMigrations[5]
      ? shellMigrations[5](state.shell)
      : { ...state.shell }
    return { ...state, shell }
  },
  6: (state: any) => {
    const core = coreMigrations[6]
      ? coreMigrations[6](state.core)
      : { ...state.core }
    return { ...state, core }
  },
  7: (state: any) => {
    const core = coreMigrations[7]
      ? coreMigrations[7](state.core)
      : { ...state.core }
    return { ...state, core }
  },
  8: (state: any) => {
    const core = coreMigrations[8]
      ? coreMigrations[8](state.core)
      : { ...state.core }
    return { ...state, core }
  },
  9: (state: any) => {
    const core = coreMigrations[9]
      ? coreMigrations[9](state.core)
      : { ...state.core }
    return { ...state, core }
  },
  10: (state: any) => {
    const core = coreMigrations[10]
      ? coreMigrations[10](state.core)
      : { ...state.core }
    return { ...state, core }
  },
  11: (state: any) => {
    const core = coreMigrations[11]
      ? coreMigrations[11](state.core)
      : { ...state.core }
    return { ...state, core }
  },
  12: (state: any) => {
    const auth = authMigrations[11](state.auth)
    return { ...state, auth }
  },
  13: (state: any) => migrate(13, state),
  14: (state: any) => migrate(14, state),
}

const migrate = (version: number, state: any) => {
  const core = migrateCore(version, state.core)
  const auth = migrateAuth(version, state.auth)
  const shell = migrateShell(version, state.shell)
  const planning = migratePlanning(
    version,
    migrateCalendar(version, state.planning)
  )
  const personalPlan = migratePersonalPlan(version, {
    ...state.personalPlan,
    calendar: migrateCalendar(version, state.personalPlan.calendar),
  })
  return { ...state, shell, core, auth, planning, personalPlan }
}
