import { Link, SxProps, Theme, Typography } from '@mui/material'

interface Props {
  sx?: SxProps<Theme>
}

export const Copyright = ({ sx = [] }: Props) => (
  <Typography
    variant="body2"
    color="text.secondary"
    sx={[...(Array.isArray(sx) ? sx : [sx])]}
  >
    {'Copyright © '}
    <Link color="inherit" href="https://alexandra.dk/">
      Alexandra Institute
    </Link>{' '}
    {new Date().getFullYear()}
    {'.'}
  </Typography>
)
