import { useResources } from '@/api'
import { useAppDispatch, useAppSelector } from '@/app'
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useCalendarActions, useCalendarSelectors } from '@/features/calendar'
import { getProp } from '@/api/parsing'

export const RouteGroupSelectorController = () => {
  const { t } = useTranslation()
  const actions = useCalendarActions()
  const { selectSelectedGroup } = useCalendarSelectors()
  const selectedGroup = useAppSelector(selectSelectedGroup)
  const dispatch = useAppDispatch()
  const { data: routeGroups } = useResources('RouteGroup')
  const groups = routeGroups?.map(group => ({
    id: group.data.id,
    name: getProp(group, 'name') ?? '',
    rgroup: getProp(group, 'rgroup') ?? '',
  }))
  return (
    <FormControl>
      <InputLabel id="select-route-group-label">
        {t('planning.selectroutegroup')}
      </InputLabel>
      <Select
        size="small"
        labelId="select-route-group-label"
        onChange={e => dispatch(actions.selectGroup(e.target.value))}
        value={selectedGroup}
        input={<OutlinedInput label={t('planning.chooseroutes')} />}
      >
        {groups?.map(it => (
          <MenuItem key={it.id} value={it.id}>
            {it.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
