import { Tooltip, Typography } from '@mui/material'
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded'
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { useStyles } from './RouteHeaderRenderer.style'
import type * as CSS from 'csstype'
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined'
import DirectionsBikeOutlinedIcon from '@mui/icons-material/DirectionsBikeOutlined'
import DirectionsWalkOutlinedIcon from '@mui/icons-material/DirectionsWalkOutlined'
import { TransportationMode } from '@/common-util/types'

const unknown = (mode: never) => <>Unknown mode {mode}</>

const transportationModeToIcon = (mode: TransportationMode) => {
  switch (mode) {
    case 'Bike':
      return <DirectionsBikeOutlinedIcon />
    case 'Car':
      return <DirectionsCarFilledOutlinedIcon />
    case 'Walking':
      return <DirectionsWalkOutlinedIcon />
    case 'Stationary':
    case 'None':
      return <></>
  }
  return unknown(mode)
}

interface Props {
  title: string
  subtitle?: string
  color: CSS.Properties['color']
  isLocked?: boolean
  transportMode?: TransportationMode
  distance?: number
  warnings?: string[]
}

export const RouteHeaderRenderer = ({
  title,
  subtitle,
  color,
  isLocked,
  transportMode = 'None',
  distance = 0,
  warnings = [],
}: Props) => {
  const { classes } = useStyles()
  const subtitleElements = [
    subtitle,
    distance ? `${(distance / 1000).toFixed(0)} km` : undefined,
  ]
    .filter(it => it !== undefined)
    .filter(it => it !== '')
    .join(' - ')
  return (
    <div style={{ backgroundColor: color }} className={classes.container}>
      <Typography
        sx={{ lineHeight: 1.5 }}
        variant="subtitle1"
        className={classes.title}
      >
        <b>{title}</b>
      </Typography>
      <Typography
        sx={{ lineHeight: 1 }}
        variant="body2"
        textAlign="center"
        className={classes.subtitle}
      >
        {subtitleElements}
      </Typography>
      <div className={classes.iconsContainer}>
        <div className={classes.iconsLeft}>
          {transportationModeToIcon(transportMode)}
        </div>

        <div className={classes.iconsRight}>
          {warnings.length > 0 && (
            <Tooltip
              disableFocusListener
              title={
                <ul>
                  {warnings.map((warning, idx) => (
                    <li key={idx}>{warning}</li>
                  ))}
                </ul>
              }
            >
              <WarningAmberRoundedIcon />
            </Tooltip>
          )}
          {isLocked ? <LockOutlinedIcon /> : <LockOpenOutlinedIcon />}
        </div>
      </div>
    </div>
  )
}
