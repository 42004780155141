import {
  combineReducers,
  configureStore,
  ThunkAction,
  Action,
  UnknownAction,
} from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import { coreReducer } from '@/features/core'
import { authReducer } from '@/features/auth'
import { planningReducer } from '@/features/planning'
import { dayRoutesReducer } from '@/features/day-routes'
import { shellReducer } from '@/shell'
import { personalPlanReducer } from '@/features/personal-plan'
import { resourceGroupsReducer } from '@/features/resource-groups'
import { rolesReducer } from '@/features/roles'
import storage from 'redux-persist/lib/storage'
import {
  createMigrate,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'

import { migrations } from './migrations'
import { PersistPartial } from 'redux-persist/es/persistReducer'

export const STORE_VERSION = 14

export const reducers = combineReducers({
  core: coreReducer,
  auth: authReducer,
  shell: shellReducer,
  planning: planningReducer,
  dayRoutes: dayRoutesReducer,
  personalPlan: personalPlanReducer,
  resourceGroups: resourceGroupsReducer,
  roles: rolesReducer,
})

const persistConfig = {
  key: import.meta.env.VITE_PLATFORM === 'desktop' ? 'root-desktop' : 'root',
  version: STORE_VERSION,
  storage,
  migrate: createMigrate(migrations, { debug: true }),
}

const resettableReducer = (
  state: ReturnType<typeof reducers> | undefined,
  action: UnknownAction
) => {
  if (action.type === 'store/reset') {
    return reducers(undefined, action)
  }
  return reducers(state, action)
}

const persistedReducer = persistReducer(persistConfig, resettableReducer)
const sentryReduxEnhancer = Sentry.createReduxEnhancer({})
export const createStore = (preloadedState?: RootState & PersistPartial) =>
  configureStore({
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
          warnAfter: 100,
        },
      }),

    devTools: import.meta.env.DEV,
    reducer: persistedReducer,
    enhancers: getDefaultEnhancers =>
      getDefaultEnhancers().concat(sentryReduxEnhancer),
    preloadedState,
  })

export const store = createStore()

export type StoreType = typeof store
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof reducers>
export type AppStore = ReturnType<typeof createStore>
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

export default store
