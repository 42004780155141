import { useEffect, useState } from 'react'
import { useRegisterSW } from 'virtual:pwa-register/react'

export const useServiceWorker = () => {
  const [swUrl, setSwUrl] = useState('')
  const {
    offlineReady: [offlineReady],
    updateServiceWorker,
  } = useRegisterSW({
    immediate: true,
    onRegisteredSW(swScriptUrl, registration) {
      setSwUrl(swScriptUrl)
      console.info('Registered SW:', swScriptUrl, registration)
    },

    onRegisterError(error) {
      console.error('SW registration error', error)
    },

    onOfflineReady() {
      console.info('Service Worker is ready for offline use, reloading page')
      setTimeout(() => window.location.reload(), 100)
    },
    onNeedRefresh() {
      updateServiceWorker()
    },
  })

  useEffect(() => {
    const onFocus = async () => {
      const registration = await navigator.serviceWorker.getRegistration()
      console.info('Checking for updates...')
      if (registration !== undefined) {
        if (!(!registration.installing && navigator)) {
          console.info('Installing...')
          return
        }

        if ('connection' in navigator && !navigator.onLine) {
          console.warn('Offline...')
          return
        }

        try {
          const resp = await fetch(swUrl, {
            cache: 'no-store',
          })
          if (resp?.status === 200) {
            await registration.update()
          }
        } catch {
          console.error('Error fetching service worker')
        }
      }
    }
    if (swUrl !== '') {
      window.addEventListener('focus', onFocus)
      return () => window.removeEventListener('focus', onFocus)
    }
  }, [swUrl])

  return { offlineReady }
}
