import { MdFavorite, MdOutlineFavoriteBorder } from 'react-icons/md'
import { useIsFetching } from '@tanstack/react-query'
import { useAppSelector } from '@/app'
import { selectors as coreSelectors } from '@/features/core'
import { Stack } from '@mui/material'

export const GlobalLoadingIndicator = () => {
  const isFetching = useIsFetching({})
  const debug = useAppSelector(coreSelectors.selectDebug)
  const indicator =
    isFetching !== 0 ? (
      <>
        <MdOutlineFavoriteBorder
          style={{ color: 'white', padding: '0.1em', fontSize: '1.2em' }}
        />
        {debug && (
          <div style={{ minWidth: '1em', color: 'white' }}>{isFetching}</div>
        )}
      </>
    ) : (
      <>
        <MdFavorite
          style={{ color: 'white', padding: '0.1em', fontSize: '1.2em' }}
        />
        {debug && <div style={{ minWidth: '1em' }}>&nbsp;</div>}
      </>
    )

  return debug ? (
    <div
      style={{
        marginLeft: 'auto',
        marginRight: 0,
        maxWidth: '3em',
      }}
    >
      <Stack direction="row">{indicator}</Stack>
    </div>
  ) : (
    indicator
  )
}
