import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()({
  logoButton: {
    padding: 0,
  },
  mobileContainer: {
    width: 110,
    '& .MuiInputBase-root': {
      paddingRight: 0,
    },
  },
  datePicker: {
    '& .MuiInputBase-input': {
      padding: '8px 8px 8px 8px',
      height: 'calc(40px - 16px)',
      textAlign: 'center',
      color: 'white',
    },
    '& .MuiInputBase-root': {
      borderColor: 'white',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white',
    },
  },
})
