import { useState } from 'react'
import { Resource } from '@alexandrainst/plana-react-api'
import { useResources } from '@/api'
import { RouteList } from '@/features/calendar/components'
import { useStyles } from './PlanningMainView.style'
import { useAppSelector } from '@/app'
import { actions, selectors } from './planningSlice'
import { getProp } from '@/api/parsing'
import { Box, Drawer, Grid, LinearProgress, Paper } from '@mui/material'
import { DateController } from '@/features/calendar/controllers'
import {
  GlobalLoadingIndicator,
  selectors as coreSelectors,
} from '@/features/core'
import { PlanningMainMenu } from './PlanningMainMenu'
import { useAutoscroll } from '@/features/calendar/util'
import { ZoomController } from '@/features/calendar/controllers/ZoomController'
import { CalendarProvider } from '@/features/calendar'
import { useCalendarSelectors } from '@/features/calendar/Calendar'

const PlanningMainViewContent = () => {
  const { classes } = useStyles()
  const {
    selectSelectedGroup,
    selectDate,
    selectScale,
    selectSelectedRoutes,
    selectGutterInterval,
    selectLegacyGrouping,
  } = useCalendarSelectors()
  const selectedGroup = useAppSelector(selectSelectedGroup)
  const date = useAppSelector(selectDate)
  const scale = useAppSelector(selectScale)
  const selectedRoutes = useAppSelector(selectSelectedRoutes)
  const gutterInterval = useAppSelector(selectGutterInterval)
  const legacyGrouping = useAppSelector(selectLegacyGrouping)
  const { isLoading, data: routes } = useResources('Route')
  const flags = useAppSelector(coreSelectors.selectFlags)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const propGroups = (r: Resource): string[] => getProp(r, 'groups') ?? []

  const routesInSelectedGroup = routes?.filter(
    r => propGroups(r) && propGroups(r).indexOf(selectedGroup) !== -1
  )

  const { ref: scrollContainerRef } = useAutoscroll(date, [], scale)

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={0}
      sx={{ background: '#1c78d3' }}
    >
      <Drawer
        anchor="top"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <Box>
          <PlanningMainMenu />
        </Box>
      </Drawer>
      <Grid item xs={8} md={10}>
        <DateController />
      </Grid>
      <Grid item xs>
        <Box
          pr={2}
          sx={{ textAlign: 'right' }}
          onClick={() => flags.useBurgerMenu && setIsDrawerOpen(!isDrawerOpen)}
        >
          <GlobalLoadingIndicator />
        </Box>
      </Grid>
      <Grid item md={12} xs={12}>
        <LinearProgress
          variant={isLoading ? 'indeterminate' : 'determinate'}
          value={100}
        />
        <Paper elevation={3} className={classes.container}>
          <ZoomController />
          <div ref={scrollContainerRef} className={classes.routeContainer}>
            <RouteList
              ids={
                [...(routesInSelectedGroup ?? [])]
                  .filter(
                    it =>
                      selectedRoutes.length === 0 ||
                      selectedRoutes.includes(it.data.id)
                  )
                  ?.sort((a, b) =>
                    (getProp(a.data.properties, 'name') ?? '').localeCompare(
                      getProp(b.data.properties, 'name') ?? ''
                    )
                  )
                  .map(r => r.data.id) ?? []
              }
              date={date}
              scale={scale}
              interval={gutterInterval}
              legacyLayout={legacyGrouping}
            />
          </div>
        </Paper>
      </Grid>
    </Grid>
  )
}

export const PlanningMainView = () => {
  return (
    <CalendarProvider
      calendarSelector={selectors.selectCalendar}
      calendarActions={actions}
    >
      <PlanningMainViewContent />
    </CalendarProvider>
  )
}
