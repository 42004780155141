import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '@/app'

export type RolesState = {
  selected: string
}

export const initialState: RolesState = {
  selected: '',
}

export const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    selectRole: (state, action: PayloadAction<string>) => {
      state.selected = action.payload
    },
    clearRole: state => {
      state.selected = ''
    },
  },
})

export const actions = rolesSlice.actions
const selectRoles = (state: RootState) => state.roles
const selectSelectedRole = createSelector(selectRoles, state => state.selected)

export const selectors = {
  selectRoles,
  selectSelectedRole,
}

export default rolesSlice.reducer
