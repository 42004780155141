import { parsedProp } from '@/api/parsing'
import {
  Cancellation,
  PropertySchemas,
  propertySetCancellationsSchema,
} from '@/api/parsing/schemas'
import { PropertySet } from '@alexandrainst/plana-react-api'

export class CancellationsModel {
  private cancellations: Cancellation[]

  constructor(cancellations: PropertySet) {
    this.cancellations = parseCancellations(cancellations)
  }

  getCancellations = () => this.cancellations
}

/**
 * Parse the cancellations PropertySet
 * @param cancellations as they are stored in the cancellation PropertySet
 * @returns an array of cancellations
 */
export const parseCancellations = (cancellations: PropertySet) => {
  const parsedCancellations = propertySetCancellationsSchema
    // The empty string means that the cancellation has been deleted, so we ignore it
    .transform(c => Object.values(c).filter(e => typeof e !== 'string'))
    .parse(cancellations.data.properties)

  return parsedCancellations.map(c => ({
    ...c,
    properties: {
      replacement: parsedProp(
        c.properties,
        PropertySchemas.propertySetCancellation.replacement
      ),
      customerPaid: parsedProp(
        c.properties,
        PropertySchemas.propertySetCancellation.customerPaid
      ),
    },
  }))
}

export const cancellationsModel = (cancellations: PropertySet) =>
  new CancellationsModel(cancellations)
