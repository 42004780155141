import { useRegisterSW } from 'virtual:pwa-register/react'
import { IoMdCloudDone } from 'react-icons/io'
import { IconButton } from '@mui/material'
import { useInstallation } from '@/features/app-information'
import SystemUpdateIcon from '@mui/icons-material/SystemUpdate'
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt'

export const ServiceWorkerStatus = () => {
  const {
    needRefresh: [needRefresh],
    offlineReady: [offlineReady],
    updateServiceWorker,
  } = useRegisterSW()
  const { platform } = useInstallation()
  const updateIcon =
    platform === 'ios' || platform === 'android' ? (
      <SystemUpdateIcon />
    ) : (
      <SystemUpdateAltIcon />
    )
  if (needRefresh) {
    updateServiceWorker(true)
  }
  return (
    <>
      {offlineReady && <IoMdCloudDone />}
      {needRefresh && (
        <IconButton color="inherit" onClick={() => updateServiceWorker(true)}>
          {updateIcon}
        </IconButton>
      )}
    </>
  )
}
