import { useStyles } from './ServiceVisitRenderer.style'
import { ServiceVisitTime } from './ServiceVisitTime'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import { Tooltip } from '@mui/material'
import { Note } from '@alexandrainst/plana-react-api'
import clsx from 'clsx'
import { forwardRef } from 'react'
import { VisitViolationsRenderer } from '@/features/violations'
import { useInstallation } from '@/features/app-information'

export interface ServiceVisitRendererProps {
  date: string
  from: number
  duration: number
  title: string
  description: string
  backgroundColor?: string
  textColor?: string
  sidebarColor?: string
  markedSelected?: boolean
  notes?: Note[]
  adhocDesc?: string
  violationsTooltipText?: string[]
  onClick?: () => void
  renderHeight?: number
}

export const ServiceVisitRenderer = forwardRef<
  HTMLDivElement,
  ServiceVisitRendererProps
>((props, ref) => {
  const {
    date,
    from,
    duration,
    title,
    description,
    backgroundColor = 'lightgray',
    textColor = 'black',
    sidebarColor = 'none',
    markedSelected = false,
    violationsTooltipText,
    notes = [],
    adhocDesc,
    onClick,
    renderHeight,
    ...rest
  } = props
  const { classes } = useStyles()
  const { isMobile } = useInstallation()

  return (
    <div
      onClick={onClick}
      style={{
        cursor: 'pointer',
        userSelect: 'none',
        WebkitUserSelect: 'none',
        backgroundColor,
        maxHeight: renderHeight,
        minHeight: renderHeight,
      }}
      {...rest}
      className={
        markedSelected
          ? clsx(classes.container, classes.selected)
          : clsx(classes.container)
      }
      ref={ref}
    >
      <div
        className={classes.content}
        style={{
          color: textColor,
        }}
      >
        <div className={classes.title}>{title}</div>
        <div className={classes.description}>{description}</div>
        <div className={classes.time}>
          <ServiceVisitTime date={date} from={from} duration={duration} />
        </div>
        {adhocDesc !== undefined && (
          <div className={classes.adhocDesc}>[{adhocDesc}]</div>
        )}
      </div>
      <div className={classes.icons}>
        <div className={classes.iconsRight}>
          {!isMobile &&
            violationsTooltipText !== undefined &&
            violationsTooltipText?.length > 0 && (
              <VisitViolationsRenderer
                violationTooltips={violationsTooltipText}
              />
            )}
          {notes.length > 0 && (
            <Tooltip disableFocusListener title={notes.length}>
              <EmailOutlinedIcon fontSize="small" />
            </Tooltip>
          )}
        </div>
      </div>
      <div className={classes.sidebar} style={{ background: sidebarColor }} />
    </div>
  )
})

ServiceVisitRenderer.displayName = 'ServiceVisitRenderer'
