import {
  CalendarColumns,
  RouteHeaderContainer,
  ServiceVisit,
} from '@/features/calendar/components'
import { Route } from './Route'
import { DndContext, DragOverlay, MouseSensor, useSensor } from '@dnd-kit/core'
import {
  restrictToFirstScrollableAncestor,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers'
import { useResource, useUpdateResource } from '@/api'
import { createPortal } from 'react-dom'
import { useState } from 'react'
import { produce } from 'immer'
import { useAppDispatch } from '@/app'
import { useCalendarActions } from '../../Calendar'
import { CALENDAR_LAST_HOUR } from '@/app/constants'
export interface RouteListProps {
  legacyLayout?: boolean
  scale?: number
  interval?: number
  headers?: boolean
  ids: string[]
  date: string
}

export const RouteList = ({
  legacyLayout = false,
  headers = true,
  scale = 1,
  interval,
  ids,
  date,
}: RouteListProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const { beginMoveVisit, endMoveVisit, cancelMoveVisit } = useCalendarActions()

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: { distance: 10 },
  })

  const [active, setActive] = useState('')

  const activeVisit = useResource('ServiceVisit', active)
  const { mutateAsync: updateServiceVisit } = useUpdateResource('ServiceVisit')

  return (
    <DndContext
      modifiers={[restrictToWindowEdges, restrictToFirstScrollableAncestor]}
      sensors={[mouseSensor]}
      onDragStart={event => {
        setActive(event.active.id.toString())
        console.debug('drag start', event)
        dispatch(
          beginMoveVisit({
            visitId: event.active.id.toString(),
            from: event.active.data.current?.visit.data.from,
            routeId: event.active.data.current?.visit.data.route,
          })
        )
      }}
      onDragEnd={async event => {
        setActive('')
        console.debug('drag end', event)
        const { active, over, delta } = event
        if (
          over &&
          over.data.current?.accepts.includes(active.data.current?.type)
        ) {
          if (activeVisit?.data !== undefined) {
            const movedVisit = produce(activeVisit.data, draft => {
              draft.data.from += delta.y / scale
              draft.data.route = over.data.current?.routeId ?? ''
            })
            dispatch(
              endMoveVisit({
                targetFrom: movedVisit.data.from,
                targetRoute: movedVisit.data.route,
              })
            )
            try {
              await updateServiceVisit(movedVisit)
            } catch {
              dispatch(cancelMoveVisit())
            }
          }
        }
      }}
    >
      {headers && (
        <RouteHeaderContainer routes={ids.map(id => ({ id, date }))} />
      )}
      <CalendarColumns
        scale={scale}
        totalMinutes={CALENDAR_LAST_HOUR * 60}
        legacyGrouping={legacyLayout}
        gutterInterval={interval}
      >
        {ids.map((id, idx) => (
          <Route
            title={false}
            key={idx}
            id={id}
            date={date}
            legacyLayout={legacyLayout}
            scale={scale}
          />
        ))}
      </CalendarColumns>
      {createPortal(
        <DragOverlay dropAnimation={null}>
          <ServiceVisitDragOverlay visitId={active} scale={scale} />
        </DragOverlay>,
        document.body
      )}
    </DndContext>
  )
}

const ServiceVisitDragOverlay = (props: { visitId: string; scale: number }) => {
  const visit = useResource('ServiceVisit', props.visitId)
  const item = visit.data ?? undefined

  if (item === undefined) {
    return null
  }
  return (
    <ServiceVisit
      visit={item}
      renderHeight={item.data.duration * props.scale}
    />
  )
}
