import { useAppDispatch, useAppSelector } from '@/app'
import { FormControlLabel, Switch } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { actions, FeatureNames, selectors as coreSelectors } from './coreSlice'
import { useOpenFeatureClient } from '@/incubator/featureFlags/FeatureFlagProvider'

interface Props {
  feature: FeatureNames
}

export const FeatureToggle = ({ feature }: Props) => {
  const { t } = useTranslation()
  const flags = useAppSelector(coreSelectors.selectFlags)
  const dispatch = useAppDispatch()
  const flag = flags[feature]
  const toggleFlag = () => dispatch(actions.setFlag({ feature, value: !flag }))
  const client = useOpenFeatureClient()
  if (!client.getBooleanValue(feature, true)) {
    return null
  }
  return (
    <FormControlLabel
      control={
        <Switch
          color="warning"
          checked={flag}
          onChange={toggleFlag}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      }
      label={t(`feature.${feature}`)}
      labelPlacement="end"
    />
  )
}
