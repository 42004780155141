import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()({
  container: {
    // backgroundColor: 'lightblue',
    padding: '5px',
    height: 'calc(100vh - 56px)',
  },
  spinner: {
    //position: 'absolute',
    // top: '45%',
    // left: '45%',
  },
  logo: {
    //position: 'absolute',
    // top: '25%',
    // left: '35%',
  },
})
