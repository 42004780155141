import { useAppDispatch, useAppSelector } from '@/app'
import { useCalendarActions, useCalendarSelectors } from '@/features/calendar'
import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

interface Props {
  scaleValues?: number[]
}

export const ScaleController = ({
  scaleValues = [0.5, 1.0, 1.5, 2.0],
}: Props) => {
  const { t } = useTranslation()
  const actions = useCalendarActions()
  const { selectScale } = useCalendarSelectors()
  const scale = useAppSelector(selectScale)
  const dispatch = useAppDispatch()
  return (
    <FormControl>
      <InputLabel id="scale-label">{t('common.label.scale')}</InputLabel>
      <Select
        size="small"
        labelId="scale-label"
        value={scale}
        onChange={s => dispatch(actions.setScale(Number(s.target.value)))}
        input={<OutlinedInput label={t('common.label.scale')} />}
      >
        {scaleValues.map(s => (
          <MenuItem key={s} value={s}>
            {s}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
