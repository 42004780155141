import { getProp, parsedProp, PropertySchemas } from '@/api/parsing'
import { Customer } from '@alexandrainst/plana-react-api'

const phoneNumbersPattern = /(?<phone>\d{8,10})/gm

class CustomerModel {
  private customer: Customer

  constructor(customer: Customer) {
    this.customer = customer
  }

  getPhone = () => getProp(this.customer, 'phone') ?? ''

  getRelatives = () => getProp(this.customer, 'relatives') ?? ''

  getRelativesPhoneNumbers = () =>
    this.getRelatives().replaceAll(' ', '').match(phoneNumbersPattern) ?? []

  getCustomerDescription = () => getProp(this.customer, 'description') ?? ''

  getCustomerName = () => this.customer.data.fullname

  getKeyInformation = () => getProp(this.customer, 'accessinfo')

  isInternalCustomer = () =>
    parsedProp(this.customer, PropertySchemas.customer.type, false) ===
    'Internal'
}

export const customerModel = (customer: Customer) => new CustomerModel(customer)
