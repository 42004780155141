import { useAppDispatch, useAppSelector } from '@/app'
import { Button, Container, TextField, Box } from '@mui/material'
import {
  setBackendServer,
  selectors as coreSelectors,
  actions,
} from '@/features/core'
import { useEffect, useState } from 'react'
import { LanguageSwitcher } from '@/features/i18n'
import { useTranslation } from 'react-i18next'
import { Copyright } from '@/features/app-information/Copyright/Copyright'
import { onlyLettersAndNumbers } from '@/common-util'
import { VersionInformation } from '@/features/app-information/VersionInformation'
import * as Sentry from '@sentry/react'

export const BackendSelector = () => {
  const { t } = useTranslation()
  const backend = useAppSelector(coreSelectors.selectBackend)
  const dispatch = useAppDispatch()
  const hash = window.location.hash
  const suggestion = hash ? hash.replaceAll('#', '') : ''
  const [systemId, setSystemId] = useState(backend.systemId)

  const validating = backend.validating !== 'idle'

  if (onlyLettersAndNumbers(suggestion) && !validating) {
    if (suggestion !== backend.systemId) {
      dispatch(setBackendServer(suggestion))
    }
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    dispatch(setBackendServer(systemId.toLocaleLowerCase()))
  }

  useEffect(() => {
    if (validating && systemId === '') {
      Sentry.captureEvent({
        message: 'SystemID bug',
        extra: { systemId, validating },
      })
      dispatch(actions.resetBackendValidation())
    }
  }, [dispatch, systemId, validating])

  const error = !!(backend.systemId && !validating && !backend.valid)

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            required
            error={error}
            //helperText={}
            fullWidth
            id="systemid"
            label={t('backendSelector.label.enterSystemId')}
            name="systemid"
            autoComplete="systemid"
            autoFocus
            disabled={validating}
            value={systemId}
            onChange={e => setSystemId(e.target.value)}
          />
          <Button
            disabled={validating || systemId === ''}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {t('common.submit')}
          </Button>
        </Box>
        <LanguageSwitcher />
        <Copyright sx={{ mt: 8, mb: 4 }} />
        <VersionInformation />
      </Box>
    </Container>
  )
}
