import { SxProps, Theme, Tooltip, Typography } from '@mui/material'
import { useGitInformation } from '@/features/app-information'

interface Props {
  sx?: SxProps<Theme>
}

export const VersionInformation = ({ sx }: Props) => {
  const { version, commitHash, branch } = useGitInformation()

  const showBranch = branch !== '' && !/^HEAD$|^main$/.test(branch)
  return (
    <Tooltip disableFocusListener title={commitHash}>
      <Typography
        variant="body2"
        color="text.secondary"
        sx={[...(Array.isArray(sx) ? sx : [sx])]}
      >
        {version} {showBranch && ` [${branch}]`}
      </Typography>
    </Tooltip>
  )
}
