import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()({
  container: {
    flex: 1,
    display: 'flex',
    borderRadius: '0px',
    border: '1px solid',
    overflow: 'hidden',
  },
  selected: {
    border: '3px solid',
  },
  content: {
    flex: 1,
    padding: '2px',
    fontFamily: 'sans-serif',
    justifyContent: 'right',
    overflow: 'hidden',
  },
  sidebar: {
    minHeight: '100%',
    minWidth: '0.5em',
  },
  icons: {
    minHeight: '100%',
    //width: '1.5em',
  },
  iconsRight: {
    position: 'relative',
    //width: '100%',
    height: '100%',
    //    position: 'absolute',
    //textAlign: 'right',
  },

  title: {
    fontSize: 'small',
    fontWeight: 'bold',
  },
  description: {
    fontSize: 'x-small',
  },
  time: {
    fontSize: 'x-small',
  },
  adhocDesc: {
    fontSize: 'x-small',
  },
})
