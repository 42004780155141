import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()({
  container: {
    // height: '3em',
    height: '100%',
  },
  title: {
    textAlign: 'center',
    // fontSize: '12px',
  },
  subtitle: {
    textAlign: 'center',
    fontSize: '10px',
  },
  iconsContainer: { display: 'flex', color: 'inherit' },
  iconsLeft: {
    flex: 1,
    textAlign: 'left',
  },
  iconsRight: {
    flex: 'auto',
    textAlign: 'right',
  },
})
