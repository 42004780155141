import { useResourceBulk } from '@/api/hooks/useResource'
import { calendarModelMapper } from '@/features/calendar/domain'
import { ServiceVisit } from '@alexandrainst/plana-react-api'
import { UseQueryResult } from '@tanstack/react-query'

function getValidServiceVisitsFromBulk(
  visit: ServiceVisit | undefined,
  childVisitsBulkQuery: UseQueryResult<ServiceVisit>[]
) {
  if (
    visit !== undefined &&
    calendarModelMapper.mapServiceVisit(visit).isParent()
  ) {
    return childVisitsBulkQuery.reduce((prev, current) => {
      if (current.isSuccess && current.data !== undefined) {
        prev.push(current.data)
      }
      return prev
    }, [] as ServiceVisit[])
  }
  return []
}

export const useChildren = (visit: ServiceVisit | undefined) => {
  const childVisitsBulkQuery = useResourceBulk(
    'ServiceVisit',
    visit !== undefined
      ? calendarModelMapper.mapServiceVisit(visit).getChildrenIds()
      : []
  )
  const childrenIds =
    visit !== undefined
      ? calendarModelMapper.mapServiceVisit(visit).getChildrenIds()
      : []
  const children = getValidServiceVisitsFromBulk(visit, childVisitsBulkQuery)
  return {
    childrenIds,
    children,
    isLoading: childVisitsBulkQuery.every(it => it.isLoading),
    isSuccess: childVisitsBulkQuery.every(it => it.isSuccess),
  }
}
