import { useAppDispatch, useAppSelector } from '@/app'
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material'
import { useCalendarActions, useCalendarSelectors } from '@/features/calendar'

export const GutterIntervalController = () => {
  const actions = useCalendarActions()
  const { selectGutterInterval } = useCalendarSelectors()
  const gutterInterval = useAppSelector(selectGutterInterval)
  const dispatch = useAppDispatch()
  const intervalValues = [15, 30, 60]
  return (
    <FormControl>
      <InputLabel id="gutter-interval-label">Gutter</InputLabel>
      <Select
        size="small"
        labelId="gutter-interval-label"
        value={gutterInterval}
        onChange={s =>
          dispatch(actions.setGutterInterval(Number(s.target.value)))
        }
        input={<OutlinedInput label="Gutter" />}
      >
        {intervalValues.map(s => (
          <MenuItem key={s} value={s}>
            {s}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
