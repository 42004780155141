import { useIdleTimer } from 'react-idle-timer'
import { useAuthentication } from '@alexandrainst/plana-react-api'
import { useAppSelector } from '@/app'
import { selectors as coreSelectors } from '@/features/core'

export const LoginStateHandler = () => {
  const automaticLogout = useAppSelector(
    coreSelectors.selectFlag('automaticLogout')
  )
  const { refresh, logout } = useAuthentication()
  const timeout = 60 * 30

  const onIdle = () => {
    if (automaticLogout) {
      logout()
    }
  }

  const onActive = () => {
    refresh()
  }

  useIdleTimer({
    onIdle,
    onActive,
    timeout: timeout * 1000,
    throttle: 500,
  })

  return null
}
