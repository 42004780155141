import { ListItem } from './PositionedList'

/**
 * Takes an array of ListItems and groups them into a list of rows.
 * Each row is itself a list of columns.
 * @param nodes the items to group
 * @param useLegacyMode flag for determining how overlapping elements are rendered for rows > 2. Set this to true to use the same layout as the Java client.
 * @returns a list of grouped items
 */
export const groupByOverlap = <T>(
  nodes: Array<ListItem<T>>,
  useLegacyMode = false
): ListItem<T>[][][] =>
  [...nodes]
    .sort((a, b) => a.position - b.position)
    .reduce(
      (result, current) => {
        const lastRow = result.pop()
        if (lastRow !== undefined) {
          const lastPosition = lastRow
            .flat()
            .reduce(
              (max, current) => Math.max(current.position + current.size, max),
              0
            )
          if (lastPosition > current.position) {
            // Match current with all columns
            let foundMatch = false
            if (useLegacyMode) {
              const lastColumn = lastRow[lastRow.length - 1]
              if (lastColumn !== undefined) {
                const lastInColumn = lastColumn[lastColumn.length - 1]
                if (
                  lastInColumn !== undefined &&
                  !foundMatch &&
                  current.position > lastInColumn.position + lastInColumn.size
                ) {
                  // match!
                  foundMatch = true
                  lastColumn.push(current)
                }
              }
            } else {
              lastRow
                .filter(it => it.length > 0)
                .forEach(column => {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  const lastInColumn = column[column.length - 1]!
                  if (
                    !foundMatch &&
                    current.position > lastInColumn.position + lastInColumn.size
                  ) {
                    // match!
                    foundMatch = true
                    column.push(current)
                  }
                })
            }
            if (foundMatch) {
              result.push([...lastRow])
            } else {
              result.push([...lastRow, [current]])
            }
          } else {
            result.push(lastRow)
            result.push([[current]])
          }
        } else {
          result.push([[current]])
        }
        return result
      },
      [] as ListItem<T>[][][]
    )
