import { PositionedList } from '../PositionedList'
import { useStyles } from './CalendarColumns.style'
import { range } from '@/common-util'
import dayjs from 'dayjs'
import React from 'react'
import { ListProps } from '../PositionedList/PositionedList'

export interface CalendarProps<T> {
  scale?: number
  totalMinutes?: number
  legacyGrouping?: boolean
  gutterInterval?: number
  lists?: ListProps<T>[]
  children?: React.ReactNode
}

export const CalendarColumns = <T,>({
  scale = 1,
  totalMinutes = 24 * 60,
  legacyGrouping = true,
  gutterInterval = 1,
  lists,
  children,
}: CalendarProps<T>) => {
  const { classes } = useStyles()
  gutterInterval = Math.max(1, Math.min(gutterInterval, 12 * 60))
  const items =
    lists?.map((list, idx) => (
      <PositionedList
        key={idx}
        scale={scale}
        legacyGrouping={legacyGrouping}
        {...list}
      />
    )) ?? React.Children.toArray(children)
  return (
    <div className={classes.container}>
      <GutterLines
        scale={scale}
        interval={gutterInterval}
        totalMinutes={totalMinutes}
      />
      <GutterLabels
        scale={scale}
        interval={gutterInterval}
        totalMinutes={totalMinutes}
      />
      <div className={classes.listContent}>
        {items?.map((item, idx) => (
          <div key={idx} className={classes.listColumn}>
            {item}
          </div>
        ))}
      </div>
    </div>
  )
}

interface GutterProps {
  scale?: number
  interval?: number
  hideTime?: boolean
  totalMinutes?: number
}

const GutterLines = ({
  scale = 1,
  interval = 60,
  totalMinutes = 24 * 60,
}: GutterProps) => {
  const { classes } = useStyles()
  return (
    <div className={classes.gutterLines}>
      {[...range(0, totalMinutes / interval)].map(it => (
        <div
          key={it}
          className={classes.gutterLine}
          style={{ top: it * scale * interval }}
        />
      ))}
    </div>
  )
}

const GutterLabels = ({
  scale = 1,
  interval = 60,
  hideTime = false,
  totalMinutes = 24 * 60,
}: GutterProps) => {
  const { classes } = useStyles()
  return (
    <div className={classes.gutter}>
      {[...range(0, totalMinutes / interval)].map(it => (
        <div
          hidden={hideTime}
          key={it}
          style={{ height: scale * interval }}
          className={
            (it * interval) % 60 === 0
              ? classes.gutterHourLabel
              : classes.gutterLabel
          }
        >
          {totalMinutes > 24 * 60 ? (
            (it * interval) / 60 > 24 ? (
              '+'
            ) : (
              <>&ensp;</>
            )
          ) : (
            ''
          )}
          {dayjs()
            .startOf('day')
            .hour((it * interval) / 60)
            .minute((it * interval) % 60)
            .format('HH:mm')}
        </div>
      ))}
    </div>
  )
}
