import {
  combineReducers,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import type { RootState } from '@/app'
import { createCalendarSlice } from '@/features/calendar'
import {
  CalendarState,
  defaultInitialState,
} from '../calendar/calendarViewSlice'

export interface PersonalPlanSlice {
  employeeId: string
}

const initialState: PersonalPlanSlice = {
  employeeId: '',
}

const initialCalendarState = {
  ...defaultInitialState,
  mode: 'editing',
} satisfies CalendarState

export const personalPlanSlice = createSlice({
  name: 'personalPlan',
  initialState,
  reducers: {
    setEmployee: (state, action: PayloadAction<string>) => {
      state.employeeId = action.payload
    },
  },
  selectors: {
    selectEmployee: state => state.employeeId,
  },
})

export const personalPlanCalendarSlice = createCalendarSlice({
  name: 'personalPlanCalendar',
  initialState: initialCalendarState,
})

const selectPersonalPlan = (state: RootState) => state.personalPlan
const selectCalendar = createSelector(
  selectPersonalPlan,
  state => state.calendar
)

export const actions = {
  ...personalPlanSlice.actions,
  ...personalPlanCalendarSlice.actions,
}
const reducer = combineReducers({
  calendar: personalPlanCalendarSlice.reducer,
  state: personalPlanSlice.reducer,
})

export const selectors = {
  selectPersonalPlan,
  selectCalendar,
  ...personalPlanSlice.selectors,
  ...personalPlanCalendarSlice.selectors,
}

export default reducer
export type PersonalPlanState = ReturnType<typeof reducer>
