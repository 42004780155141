import { Box, List, ListItem } from '@mui/material'
import {
  GutterIntervalController,
  RouteSelectorController,
  RouteGroupSelectorController,
  LegacyGroupingController,
  ScaleController,
} from '@/features/calendar/controllers'

export const PlanningMainMenu = () => {
  return (
    <Box sx={{ padding: '5px' }}>
      <LegacyGroupingController />
      <ScaleController />
      <GutterIntervalController />
      <Box sx={{ padding: '5px' }}>
        <RouteGroupSelectorController />
        <RouteSelectorController />
      </Box>
    </Box>
  )
}

export const PlanningMainMenuList = () => {
  return (
    <List>
      <ListItem disablePadding>
        <LegacyGroupingController />
      </ListItem>
      <ListItem disablePadding>
        <ScaleController />
      </ListItem>
      <ListItem disablePadding>
        <GutterIntervalController />
      </ListItem>
      <ListItem disablePadding>
        <RouteGroupSelectorController />
      </ListItem>
      <ListItem disablePadding>
        <RouteSelectorController />
      </ListItem>
    </List>
  )
}
