import { Tooltip } from '@mui/material'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import { tooltip, useStyles } from './VisitViolationsRenderer.styles'

type VisitViolationsRenderer = {
  violationTooltips: string[]
}

export const VisitViolationsRenderer = ({
  violationTooltips,
}: VisitViolationsRenderer) => {
  const { classes } = useStyles()
  return (
    <Tooltip
      disableFocusListener
      title={
        <ul>
          {violationTooltips.map((v, i) => (
            <li key={i}>{v}</li>
          ))}
        </ul>
      }
      componentsProps={{
        tooltip: {
          sx: tooltip,
        },
      }}
    >
      <WarningRoundedIcon
        color="error"
        fontSize="small"
        className={classes.warning}
      />
    </Tooltip>
  )
}
