import { ResourceType } from '@alexandrainst/plana-react-api'

export const resourceKeys = <T extends ResourceType>(type: T) => ({
  all: [{ entity: type }] as const,
  lists: () => [{ ...resourceKeys(type).all[0], scope: 'list' }] as const,
  bySourceScope: () =>
    [{ ...resourceKeys(type).all[0], scope: 'bySource' }] as const,
  bySourceListsType: (sourceType: ResourceType) =>
    [
      {
        ...resourceKeys(type).bySourceScope()[0],
        sourceType,
      },
    ] as const,
  bySource: (
    sourceType: ResourceType,
    sourceId: string,
    from: string,
    to: string
  ) =>
    [
      {
        ...resourceKeys(type).bySourceScope()[0],
        sourceType,
        sourceId,
        from,
        to,
      },
    ] as const,
  detailsScope: () =>
    [{ ...resourceKeys(type).all[0], scope: 'details' }] as const,
  details: (id: string) =>
    [{ ...resourceKeys(type).detailsScope()[0], id }] as const,
})
