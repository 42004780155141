import React from 'react'
import { useTranslation } from 'react-i18next'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import LanguageIcon from '@mui/icons-material/Language'
import { IconButton } from '@mui/material'

const lngs = {
  en: { nativeName: 'English', flag: '🇺🇸' },
  da: { nativeName: 'Dansk', flag: '🇩🇰' },
} as const

const lngKeys = ['en', 'da'] as const

export const LanguageSwitcher = () => {
  const { i18n } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const selectLanguage = (language: string) => {
    i18n.changeLanguage(language)
    handleClose()
  }
  return (
    <>
      <IconButton
        color="inherit"
        id="select-language-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <LanguageIcon />
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="select-language-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {lngKeys.map((key, idx) => (
          <MenuItem key={idx} onClick={() => selectLanguage(key)}>
            {`${lngs[key].flag} ${lngs[key].nativeName}`}
          </MenuItem>
        ))}
      </Menu>
      {/* {Object.keys(lngs).map((lng: string) => (
        <button
          key={lng}
          style={{
            fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal',
          }}
          type="submit"
          onClick={() => i18n.changeLanguage(lng)}
        >
          {lngs[lng].nativeName}
        </button>
      ))} */}
    </>
  )
}
