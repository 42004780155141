import { Violation } from '@alexandrainst/plana-react-api'
import { TFunction } from 'i18next'

export const translateViolation = (
  t: TFunction<'translation', undefined>,
  violation: Violation
) => {
  switch (violation.data.subtype) {
    case 'CalculationError':
      return 'Error'
    default:
      return t('violations.subType.' + violation.data.subtype)
  }
}
