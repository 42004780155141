import { Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

export const ServiceVisitTime = ({
  date,
  from,
  duration,
  large,
}: {
  date: string
  from: number
  duration: number
  large?: boolean
}) => {
  const { t } = useTranslation()
  const begin = dayjs(date, 'YYYY-MM-DD')
    .utc()
    .hour(from / 60)
    .minute(from % 60)
  const end = begin.add(duration, 'minute')
  const formattedDuration = (duration: number) =>
    dayjs
      .duration(duration, 'minutes')
      .format(
        duration / 60 >= 1
          ? t('common.time.hoursMinutesFormat')
          : t('common.time.minutesFormat')
      )
  const timeString = `${begin.format('HH:mm')} - ${end.format(
    'HH:mm'
  )} (${formattedDuration(duration)})`

  return (
    <>
      {large ? (
        <Typography fontSize="1.125rem" fontWeight={400} gutterBottom>
          {timeString}
        </Typography>
      ) : (
        <>{timeString}</>
      )}
    </>
  )
}
