import { ResourceType, TypedResource } from '@alexandrainst/plana-react-api'

export type CachedResourceList<T extends ResourceType> = {
  type: T
  items: TypedResource<T>[]
  currentVersion: number
  sinceVersion: number
}

export const isCacheResourceList = <T extends ResourceType>(
  type: T,
  elm: CachedResourceList<T> | unknown
): elm is CachedResourceList<T> => (elm as CachedResourceList<T>).type === type

export const detailsCacheUpdater =
  <T extends ResourceType>(newElm: TypedResource<T>) =>
  (oldElm: TypedResource<T> | undefined) =>
    oldElm === undefined || oldElm.data.version < newElm.data.version
      ? newElm
      : oldElm
