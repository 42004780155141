import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()({
  datePicker: {
    '& .MuiInputBase-input': {
      padding: '8px 0 8px 0',
      textAlign: 'center',
    },
    '& .MuiInputBase-root': {
      paddingRight: 0,
    },
  },
})
