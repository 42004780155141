import { PropertySchemas, parsedProp } from '@/api/parsing'
import { ActivityLog } from '@alexandrainst/plana-react-api'

export const activityLogModel = (activityLog: ActivityLog) => ({
  totalTransportInSeconds: () =>
    parsedProp(
      activityLog,
      PropertySchemas.activityLog.transportInSeconds,
      false
    ),
  visitCount: () =>
    parsedProp(activityLog, PropertySchemas.activityLog.visitcount, false),
  visitDuration: () =>
    parsedProp(activityLog, PropertySchemas.activityLog.visitduration, false),
  totalTransportInMeters: () =>
    parsedProp(activityLog, PropertySchemas.activityLog.distance, false),
})
