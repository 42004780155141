import { TransportationMode, VisitState } from '@/common-util/types'
import { Resource } from '@alexandrainst/plana-react-api'
import { produce } from 'immer'
import { z } from 'zod'
import { PropertySchema } from './schemas'

type State = {
  state: VisitState
}

type NoteProperties = {
  seen_by: string
  editor: string
}

type ServiceVisitProperties = {
  originRoute: string
}

type CustomerProperties = {
  familyMember?: string
  familyParent?: string
  type?: 'Internal' | 'External'
  accessinfo: string
}

type CommonProperties = {
  name: string
  rgroup: string
  groups: string[]
  phone: string
  description: string
  relatives: string
  color: number
  lockedToCalendar: boolean
  transportMode: TransportationMode
}

type Properties = Partial<
  CommonProperties &
    State &
    NoteProperties &
    ServiceVisitProperties &
    CustomerProperties
>

const isResourceLike = (obj: unknown): obj is Resource =>
  obj !== null &&
  typeof obj === 'object' &&
  'data' in obj &&
  obj.data !== null &&
  typeof obj.data === 'object' &&
  'properties' in obj.data

/**
 * Extract a property from either a resource or a properties string
 * @deprecated for properties moved to zod based extraction
 * @param props either a resource or a property string
 * @param name name of the property
 * @returns the raw string stored under the specified name
 */
export function getProp<
  T extends Record<PropertyKey, unknown>,
  K extends keyof Properties,
>(props: T | Resource | string | undefined, name: K): Properties[K] {
  if (isResourceLike(props)) {
    return JSON.parse(props.data.properties)[name]
  }
  if (typeof props === 'string') {
    return props ? JSON.parse(props)[name] : undefined
  }
  if (props && name in props) {
    return props[name]
  }
  return undefined
}
/**
 *
 * @deprecated use setParsedProp instead to gain type safety
 * @param props -
 * @param name -
 * @param value -
 * @returns -
 */
export function setProp<
  T extends Record<PropertyKey, unknown>,
  K extends keyof Properties,
  V,
>(props: T | string, name: K, value: V): T | string {
  if (typeof props === 'string') {
    const p = JSON.parse(props)
    return JSON.stringify(_setProp(p, name, value))
  }
  return _setProp(props, name, value)
}

function _setProp<
  T extends Record<PropertyKey, unknown>,
  K extends keyof Properties,
  V,
>(props: T, name: K, value: V): T {
  return { ...props, [name]: value }
}

type PropsOrResource<T extends Resource | string> = T extends string
  ? string
  : T

/**
 * Set the a property to the specified value.
 * The value will be validated using the supplied schema and will throw
 * and exception if the value is invalid.
 * @param propsOrResource the Resource or a string representing the properties
 * @param schema the Zod schema to use for parsing the property
 * @param value the value to assign to the property
 * @returns either a copy of the passeed Resource with the property set or a
 * copy of the provided property string with the property set
 */
export const setParsedProp = <T extends Resource | string>(
  propsOrResource: T,
  schema: PropertySchema,
  value: unknown
): PropsOrResource<T> => {
  if (typeof propsOrResource === 'string') {
    return _setParsedProp(propsOrResource, schema, value) as PropsOrResource<T>
  } else {
    return produce(propsOrResource, (draft: Resource) => {
      draft.data.properties = _setParsedProp(
        draft.data.properties,
        schema,
        value
      )
    }) as PropsOrResource<T>
  }
}

const _setParsedProp = (
  props: string,
  schema: PropertySchema,
  value: unknown
) => {
  const parsedProps = JSON.parse(props)
  parsedProps[schema.propertyName] =
    typeof value === 'string' ? value : JSON.stringify(value)
  schema.parse(parsedProps)
  return JSON.stringify(parsedProps)
}

/**
 * Delete a property from a Resource or a property string.
 *
 * Note that deleting a property is done by setting its value to "null"
 * @param propsOrResource the Resource or a string representing the properties
 * @param schema the Zod schema to use identifying the property
 * @returns either a copy of the Resource object with the property deleted or
 * a copy of the string representation with the property deleted.
 */
export const deleteProp = <TInput extends Resource | string>(
  propsOrResource: TInput,
  schema: PropertySchema
): PropsOrResource<TInput> => {
  if (typeof propsOrResource === 'string') {
    return _deleteProp(propsOrResource, schema) as PropsOrResource<TInput>
  } else {
    return produce(propsOrResource, (draft: Resource) => {
      draft.data.properties = _deleteProp(draft.data.properties, schema)
    }) as PropsOrResource<TInput>
  }
}

const _deleteProp = (props: string, schema: PropertySchema) => {
  const parsedProps = JSON.parse(props)
  parsedProps[schema.propertyName] = null
  return JSON.stringify(parsedProps)
}

/**
 * Extract and parse a property from either a Resource or a raw property string.
 * This method should be preffered over {@link getProp}
 * @param propsOrResource the Resource or a string from which to extract the property
 * @param schema the Zod schema to use for parsing the property
 * @param failOnError set this to true to force zod parse errors to throw exception
 * @returns either a valid parsed property object or undefined if it does not exist
 */
export const parsedProp = <TValues>(
  propsOrResource: Resource | string | undefined,
  schema: z.Schema<TValues, z.ZodTypeDef, unknown>,
  failOnError = false
) => {
  if (propsOrResource === undefined || propsOrResource === null) {
    return undefined
  }
  let json
  if (isResourceLike(propsOrResource)) {
    json = JSON.parse(propsOrResource.data.properties)
  }
  if (typeof propsOrResource === 'string') {
    json = JSON.parse(propsOrResource)
  }
  if (failOnError) {
    return schema.parse(json)
  }
  const p = schema.safeParse(json)
  if (p.success) {
    return p.data
  }
  return undefined
}
