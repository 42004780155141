import { AuthState } from '@/features/auth/authSlice'

type AuthStateV1 = {
  processing: 'idle' | 'pending'
  userHasMadeBiometricChoice: boolean
  usersBiometricSecretChoice: boolean
  biometricSecretId: string
}

export const migrations = {
  11: (state: AuthStateV1): AuthState => {
    return { ...state }
  },
} as const
