import {
  useApi,
  ResourceType,
  TypedResource,
} from '@alexandrainst/plana-react-api'
import { useQueries, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { resourceKeys } from './keys'

export const useResourceBulk = <T extends ResourceType>(
  type: T,
  ids: string[],
  options?: Partial<
    UseQueryOptions<
      Promise<TypedResource<T>>,
      unknown,
      TypedResource<T>,
      ReturnType<ReturnType<typeof resourceKeys<T>>['details']>
    >
  >
) => {
  const { fetchResource } = useApi(type)
  return useQueries({
    queries: ids.map(id => {
      const queryKey = resourceKeys(type).details(id ?? '')
      const [{ id: safeId }] = queryKey
      return {
        queryKey,
        queryFn: () => fetchResource(safeId),
        enabled:
          typeof id === 'string' &&
          id !== undefined &&
          id !== '' &&
          options?.enabled &&
          !!options.enabled,
      }
    }),
    combine: q => q.map(it => ({ ...it })),
  })
}

export const useResource = <T extends ResourceType>(
  type: T,
  id: string | undefined,
  options?: Partial<
    UseQueryOptions<
      Promise<TypedResource<T>>,
      unknown,
      TypedResource<T>,
      ReturnType<ReturnType<typeof resourceKeys<T>>['details']>
    >
  >
) => {
  const { fetchResource } = useApi(type)
  return useQuery({
    queryKey: resourceKeys(type).details(id ?? ''),
    queryFn: ({ queryKey: [{ id }], signal }) =>
      fetchResource(id, { fetchOptions: { signal } }),
    enabled: typeof id === 'string' && id !== undefined && id !== '',
    ...options,
  })
}
